/* EmployeeCardSlider.css */
.es-cardsliderwrapper {
    overflow: hidden;
    position: relative;
    padding-left: 100px;
  }
  
  .es-cardslider {
    display: flex;
    gap: 30px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    padding: 10px; /* Added padding for arrow buttons */
  }
  
  .es-cardslider::-webkit-scrollbar {
    display: none;
  }
  
 /* EmployeeCardSlider.css */
.card {
  background-image: linear-gradient(to bottom right, #333333,  #0D0D0D  );
    flex: 0 0 auto;
    scroll-snap-align: start;
    width: 468px;
    height: 420px; /* Adjust the height as needed */
    border-radius: 8px;
    overflow: hidden;
    border: 0px;
    transition: transform 0.3s ease-in-out;
    
  }
  
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 40px; 
    margin-bottom: 40px;
  }
  
  .card-content p {
    text-align: left;
    margin: 10px 10px; 
    padding: 10px;
    line-height: 30px;
    font-size: 15px;
    font-family: 'Open Sans', 'Regular';
  }
  
  .card:nth-child(1) .es-icon {
    position: absolute;
    top: 300px; 
    left: 50px; 
    height: 50px;
    background-size: cover;
    background-position: left; 
  }

  .card:nth-child(1) .es-name  h3  {
    margin-top: 15px;
  }
  
  .card:nth-child(1) .es-name  p {
    margin-top: -20px;
    margin-left: -10px ;
    
  } 

  .card:nth-child(2) .es-icon {
    position: absolute;
    top: 300px; 
    left: 20px; 
    height: 50px;
    background-size: cover;
    background-position: left; 
  }

  .card:nth-child(2) .es-name  h3  {
    margin-top: 45px;
    margin-left: 20px;
  }  

  .card:nth-child(2) .es-name  p {
    margin-top: -20px;
    margin-left: -10px ;
    margin-left: 10px;
    
  } 

  .card:nth-child(3) .es-icon {
    position: absolute;
    top: 300px; 
    left: 30px; 
    height: 50px;
    background-size: cover;
    background-position: left; 
  }

  .card:nth-child(3) .es-name  h3  {
    margin-top: 45px;
  }  

  .card:nth-child(3) .es-name  p {
    margin-top: -20px;
    margin-left: -10px ;
    
  } 
  
  
  .card:nth-child(4) .es-icon {
    position: absolute;
    top: 300px; 
    left: 20px; 
    height: 50px;
    background-size: cover;
    background-position: left; 
  }

  .card:nth-child(4) .es-name  h3  {
    margin-top: 15px;
  }  

  .card:nth-child(4) .es-name  p {
    margin-top: -20px;
    margin-left: -10px ;
    
  } 

  
  .card:nth-child(5) .es-icon {
    position: absolute;
    top: 300px; 
    left: 50px; 
    height: 50px;
    background-size: cover;
    background-position: left; 
  }

  .card:nth-child(5) .es-name  h3  {
    margin-top: 15px;
  }  

  .card:nth-child(5) .es-name  p {
    margin-top: -20px;
    margin-left: -10px ;
    
  } 

  
  .card:nth-child(6) .es-icon {
    position: absolute;
    top: 300px; 
    left: 20px; 
    height: 50px;
    background-size: cover;
    background-position: left; 
  }

  .card:nth-child(6) .es-name  h3  {
    margin-top: 45px;
    margin-left: 30px;
  }  

  .card:nth-child(6) .es-name  p {
    margin-top: -20px;
    margin-left: -10px ;
    margin-left: 20px;
    
  } 

  
  .card:nth-child(7) .es-icon {
    position: absolute;
    top: 300px; 
    left: 20px; 
    height: 50px;
    background-size: cover;
    background-position: left; 
  }

  .card:nth-child(7) .es-name  h3  {
    margin-top: 15px;
  }  

  .card:nth-child(7) .es-name  p {
    margin-top: -20px;
    margin-left: -10px ;
    
  } 

  
  .card:nth-child(8) .es-icon {
    position: absolute;
    top: 300px; 
    left: 30px; 
    height: 50px;
    background-size: cover;
    background-position: left; 
  }

  .card:nth-child(8) .es-name  h3  {
    margin-top: 15px;
    margin-left: -50px ;

  }  

  .card:nth-child(8) .es-name  p {
    margin-top: -20px;
    margin-left: -60px ;
    
  } 

  
  .card:nth-child(9) .es-icon {
    position: absolute;
    top: 300px; 
    left: 40px; 
    height: 50px;
    background-size: cover;
    background-position: left; 
  }

  .card:nth-child(9) .es-name  h3  {
    margin-top: 15px;
  }  

  .card:nth-child(9) .es-name  p {
    margin-top: -20px;
    margin-left: -10px ;
    
  } 

  .wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-right: 13px;
    
  }
  
  .wrapper i {
    background: linear-gradient(to right,#333333, #0D0D0D);
    background-color: transparent;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    border-color: #333333;
    transition: background-color 0.3s ease;
  }
  
  .employee-card-arrow-wrapper i:hover {
    background-color: #f0f0f0;
  }
  
  .employee-card-arrow-wrapper i::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 15px 10px 0;
    border-color: transparent #000 transparent transparent;
  }
  
  .wrapper i#left::before {
    transform: translate(-50%, -50%) rotate(180deg);
  }
  
  @media (max-width: 768px) {
    .es-cardsliderwrapper {
      padding-left: 20px;
    }
    .es-cardslider {
      gap: 10px;
      padding: 5px; /* Added padding for arrow buttons */
    }
    .card {
      width: 100%;
      height: auto;
      border-radius: 8px;
      overflow: hidden;
      border: 0px;
      transition: transform 0.3s ease-in-out;
    }
    .card:hover {
      transform: scale(1.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
    .card-content {
      padding-top: 20px;
      margin-bottom: 20px;
    }
    .card-content p {
      margin: 5px 5px;
      padding: 5px;
      line-height: 20px;
      font-size: 12px;
    }
    .card:nth-child(1)  .es-icon {
      top: 150px;
      left: 10px;
      height: 30px;
    }
    .card:nth-child(1) .es-name h3 {
      margin-top: 10px;
      font-size: 14px;
    }
    .card:nth-child(1)  .es-name p {
      margin-top: -15px;
      margin-left: -5px;
      font-size: 12px;
    }

    .card:nth-child(1)  .es-icon {
      top: 180px;
      left: 30px;
      height: 30px;
    }
    .card:nth-child(1) .es-name h3 {
      margin-top: 10px;
      font-size: 14px;
    }
    .card:nth-child(1)  .es-name p {
      margin-top: -15px;
      margin-left: -5px;
      font-size: 12px;
    }

    .card:nth-child(2)  .es-icon {
      top: 180px;
      left: 20px;
      height: 30px;
    }
    .card:nth-child(2) .es-name h3 {
      margin-top: 40px;
      font-size: 14px;
      margin-left: 20px;
    }
    .card:nth-child(2)  .es-name p {
      margin-top: -15px;
      margin-left: 13px;
      font-size: 12px;
    }
    .card:nth-child(3)  .es-icon {
      top: 180px;
      left: 10px;
      height: 30px;
    }
    .card:nth-child(3) .es-name h3 {
      margin-top: 30px;
      font-size: 14px;
    }
    .card:nth-child(3)  .es-name p {
      margin-top: -15px;
      margin-left: -5px;
      font-size: 12px;
    }

    .card:nth-child(4)  .es-icon {
      top: 180px;
      left: 20px;
      height: 30px;
    }
    .card:nth-child(4) .es-name h3 {
      margin-top: 20px;
      font-size: 14px;
    }
    .card:nth-child(4)  .es-name p {
      margin-top: -15px;
      margin-left: -5px;
      font-size: 12px;
    }

    .card:nth-child(5)  .es-icon {
      top: 180px;
      left: 30px;
      height: 30px;
    }
    .card:nth-child(5) .es-name h3 {
      margin-top: 20px;
      font-size: 14px;
    }
    .card:nth-child(5)  .es-name p {
      margin-top: -15px;
      margin-left: -5px;
      font-size: 12px;
    }

    .card:nth-child(6)  .es-icon {
      top: 180px;
      left: 20px;
      height: 30px;
    }
    .card:nth-child(6) .es-name h3 {
      margin-top: 40px;
      font-size: 14px;
      margin-left: 85px;
    }
    .card:nth-child(6)  .es-name p {
      margin-top: -15px;
      margin-left: 80px;
      font-size: 12px;
    }

    .card:nth-child(7)  .es-icon {
      top: 180px;
      left: 20px;
      height: 30px;
    }
    .card:nth-child(7) .es-name h3 {
      top: 180px;
      font-size: 14px;
      margin-left: 25px;
    }
    .card:nth-child(7)  .es-name p {
      margin-top: -15px;
      margin-left: 20px;
      font-size: 12px;
    }

    .card:nth-child(8)  .es-icon {
      top: 180px;
      left: 40px;
      height: 30px;
    }
    .card:nth-child(8) .es-name h3 {
      margin-top: 20px;
      font-size: 14px;
      margin-left: 5px;
    }
    .card:nth-child(8)  .es-name p {
      margin-top: -15px;
      margin-left: 5px;
      font-size: 12px;
    }

    .card:nth-child(9)  .es-icon {
      top: 180px;
      left: 30px;
      height: 30px;
    }
    .card:nth-child(9) .es-name h3 {
      margin-top: 15px;
      font-size: 14px;
      margin-left: 10px;
    }
    .card:nth-child(9)  .es-name p {
      margin-top: -15px;
      margin-left: 5px;
      font-size: 12px;
    }
  }
  