.cmp-body-content__section {
  background-color: #000;
  color: #fff;
  padding: clamp(15px, 2vw, 30px);
  text-align: center;

  @media (min-width:1024px) {
    margin-top: -83px;
  }
}

.cmp-body-content__title h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: clamp(12px, 1.5vw, 20px);
  font-weight: bold;
  margin-bottom: clamp(15px, 2vw, 25px);
}

.cmp-body-content__description {
  font-size: clamp(12px, 1.5vw, 16px);
  line-height: 1.6;
  margin: 0;
}

.cmp-body-content__description p {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  font-size: clamp(12px, 1.5vw, 18px);
}