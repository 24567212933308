.titleName1 h1 {
  /* padding-top:2px; */
  margin-top: 22px;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  font-family: "Open Sans", "Regular";
}

.sub {
  font-size: 16px;
  margin-top: 20px;
  line-height: 30px;
  font-family: "Open Sans", "Regular";
  text-align: center;
}

.sub1 {
  font-size: 16px;
  margin-top: 20px;
  line-height: 30px;
  font-family: "Open Sans", "Regular";
  text-align: center;
}

.iconOne {
  padding: 15px;
  width: auto;
}

.iconTwo {
  padding: 15px;
  width: auto;
}

.iconThree {
  padding: 15px;
  width: auto;
}

.iconFour {
  padding: 15px;
  width: auto;
  margin-top: 30px;
}

.iconFive {
  padding: 15px;
  width: auto;
}

.iconEighteen {
  padding: 15px;
  width: auto;


}

.iconSixteen img {
  padding: 30px;
  width: auto;
}



.iconSix,
.iconSeven {
  padding: 20px;
  width: auto;
  justify-content: space-evenly;
}

.iconEight,
.iconNine,
.iconTen {
  padding: 20px;
  width: auto;
  justify-content: space-evenly;
}

.iconEleven {
  padding: 20px;
  width: auto;
  justify-content: space-evenly;
}

.iconTwelve {
  padding: 20px;
  width: auto;
  justify-content: space-evenly;
}

.icon13,
.icon14,
.icon15 {
  padding: 10px;
  width: auto;
  margin-right: 21px;
  margin-top: 8px;
}

.icon17 {
  margin-left: -21px;
  margin-top: -9px;
  justify-content: space-evenly;
  padding: 30px;
  width: auto;
}

.titleName2 h1 {
  margin-top: 60px;
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  font-family: "Open Sans", "Regular";

}

.boxContainer {
  display: flex;
  background-color: black;
  margin-top: 30px;

}

.boxContainer img {
  width: 100%;
}

.slider-container {
  display: flex;
  justify-content: center;



}

.slider-container img {
  filter: grayscale(100%);
}



.slider-container div {
  display: inline-block;
  margin-right: 21px;
  margin-top: 21px;
}



.slider-container img:hover {
  filter: grayscale(0);
}

.bottomImage {
  display: flex;
  background-color: black;
  width: auto;
}


.icon-containerTwo {
  display: flex;
  justify-content: center;
  white-space: nowrap;
  position: relative;
}


.icon-containerTwo img {
  overflow-x: auto;
  white-space: nowrap;
  height: 75px;
  margin-top: 20px;
}


/* LevelUp Section CSS */




.temporary-submit-adwf-levelup-section {
  margin-top: 70px;
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.temporary-submit-adwf-levelup-section h1 {
  font-family: 'Open Sans', 'Regular';
  font-size: 45px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 50px;
  width: 40%;
}


.temporary-submit-adwf-levelup-text {
  display: flex;
  padding-left: 180px;
  justify-content: center;
  align-items: center;

}


.temporary-submit-adwf-levelup-para p {
  font-family: 'Open Sans', 'Regular';
  font-size: 16px;
  line-height: 30px;
  text-align: left;

}


.temporary-submit-adwf-levelup-btn button {
  margin-top: 50px;
  padding: 5px 40px;
  border-radius: 40px;
  background: linear-gradient(to right, #FE780C, #FE3908);
  color: white;
  font-weight: 600;
  border: none;
  cursor: pointer;
  /* background-color: black;
  border:linear-gradient(to right,#Fe780C,#FE3908);   */
}

.temporary-submit-adwf-levelup-btn button:hover {
  box-shadow: 0 0 15px #FE780C, 0 0 20px #FE3908;
}

.temporary-submit-adwf-levelup-btn button:hover::after {
  content: url('../contents/Careers/careers-assests/carrow2.png');
  position: absolute;
  margin-top: -29px;
  padding-left: 85px;
}

.temporary-submit-adwf-levelup-btn h6 {
  font-family: 'Open Sans', 'Regular';
  font-size: 15px;
}

.temporary-submit-adwf-levelup-adwf_lasvegas {
  flex: 1;
  display: flex;
  padding-left: 120px;
  margin-top: -20px;
  margin-right: 200px;
}



@media (max-width: 768px) {

  .slider-container {
    margin-left: 0px;
    animation: slider-container 7s linear infinite alternate;
  }

  @keyframes slider-container {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-190%);
    }
  }


  .icon-containerTwo {
    display: flex;
    justify-content: center;
    white-space: nowrap;
    position: relative;
    width: 625px;
  }

  .bottomImage {
    position: relative;
    animation-fill-mode: backwards;
    animation: bottomImage 10s linear infinite alternate;
  }

  .boxContainer {
    margin-top: 10px;

  }

  .titleName2 h1 {
    font-size: 32px;
  }

  .titleName1 h1 {
    font-size: 32px;
  }

  .sub {
    font-size: 12px;
    width: 90%;
    text-align: center;
    margin-left: 20px;
  }

  .sub1 {
    font-size: 12px;
    width: 90%;
    margin-left: 20px;
  }


  /* LevelUp Section CSS */
  .temporary-submit-adwf-levelup-section h1 {
    font-size: 22px;
    width: 80%;

  }

  .temporary-submit-adwf-levelup-text {
    flex-direction: column;
    gap: 20px;
    margin-left: -150px;
  }

  .temporary-submit-adwf-levelup-para p {
    font-size: 12px;
    width: 100%;
    margin-top: -40px;
    text-align: center;
    margin-left: -20px;
    font-family: 'Open Sans', 'Regular';
    line-height: 25px;
  }

  .temporary-submit-adwf-levelup-btn button {
    padding: 7px 10px;
    margin-left: 70px;

  }

  .temporary-submit-adwf-levelup-btn button:hover::after {
    content: none;
    position: absolute;
    margin-top: 10px;
    padding-left: 80px;
  }

  .temporary-submit-adwf-levelup-btn button h6 {
    font-size: 12px;
    text-align: center;
    /* margin-left: 20px; */

  }

  .temporary-submit-adwf-levelup-btn button {
    margin-top: 30px;
    padding: 0px 30px;
    border-radius: 40px;
    background: linear-gradient(to right, #FE780C, #FE3908);
    color: white;
    font-weight: 600;
    border: none;
    cursor: pointer;
    background-color: black;
    border: linear-gradient(to right, #Fe780C, #FE3908);
  }

  .temporary-submit-adwf-levelup-btn button:hover::after {
    display: none;
  }

  .temporary-submit-adwf-levelup-btn button:hover {
    box-shadow: 0 0 15px #FE780C, 0 0 20px #FE3908;
  }

  .temporary-submit-adwf-levelup-adwf_lasvegas {
    padding-left: 0;
    display: none
  }

}


/*Media Query For Screens with 1366px */


@media (max-width: 1366px) and (min-width: 1031px) {

  .slider-container {
    margin-left: 0px;

  }

}