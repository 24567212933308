
/* variables */
:root {
    --main-color: #2288ff;
    --second-color: #193f6a;
    --text-color: #314862;
    --bg-color: #000000;
    --p-color:white;
    /* Box Shadow */
    --box-shadow: 2px 2px 18px rgb(14 52 54 / 15%);
}

.service-digital-martec-wrapper{
    width: 100vw;
}
/* Head section */
.service-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Open Sans', 'Regular';
}
.mas-nav{
    width: 100vw;
}
.service-container {
    display: flex;
    flex-direction: column;
}

.service-head-text {

    margin-bottom: 0;
    margin-top: -30rem;
    margin-left: 20rem;
    text-align: center;
    font-family: 'Open Sans', 'Regular';
    font-size: 45px;
    color: white;
    font-weight: bold;
    width: 63%;
}

.service-img {
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    position: relative;
    width: 100%;
    z-index: -1;
    background-color: black;
    /* filter: grayscale(100%); */
    filter:brightness(55%);
    top: 0;
    margin-top: -100px;
}


.service-head-section {
    position: relative;
    width: 100vw;
    background: no-repeat center center fixed;
    background-size: cover;
    filter: grayscale(100%);
    background-color: black;
    z-index: -1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1.5);
}

.service-head-section::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    z-index: -1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

/* Service Section */
.service-section{
    margin-top: 70px;
    display: flex;
    justify-content: center;
    margin-left: 160px;

}

.service-content {
    position: relative;
    padding-left: 100px;
    width: 100vw;
}


.service-left img {
    display: block;
    position: inherit;
    object-fit: cover;
    margin-top: 8rem;
}

.left-text {
    display: inline-flex;
    font-family: 'Open Sans', 'Regular';
    line-height: 30px;
    position: relative;
    font-size: 16px;
    color: var(--p-color);
   text-align: left;
    bottom: -40px;
}

.service-right {
    display: block;
    position: absolute;
    top: 7rem;
    right: 0;
    left: 42rem;
    width: 47%;
    height: 100%;
}



.service-right h3{
    font-size: 30px;
    font-family: 'Open Sans', 'Regular';
    font-weight: bold;
}


.right-text {
    display: inline-flex;
    font-family: 'Open Sans', 'Regular';
    font-size: 16px;
    color: var(--p-color);
    text-align: left;
    line-height: 30px;


} 
.service-right h1{
    font-size: 30px;
    color: #fff;
    margin-top: -5px;
    width: 100%;
    font-weight: 600;
}

/* Stack section */
.stack-container {
    padding-top: 20px;
    width: 100%;
}


.stack-container h1 {
    color: var(--p-color);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Open Sans', 'Regular';
    font-size: 45px;
    padding-bottom: 10px;
}

.stack-container p {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    font-family: 'Open Sans', 'Regular';
    color: var(--p-color);
}

.stack-mainDiv{
    display: flex;
    padding-left: 450px;
    padding-top: 15px;
}

.stack-mainDiv h3{
    color: #FE780C;
    text-align: left;
    font-family: 'Open Sans', 'Regular';
    font-size: 25px;

}

.stack-mainDiv li{
    text-align: left;
    line-height: 30px;
    font-size: 16px;
    font-family: 'Open Sans', 'Regular';
    color: var(--p-color);
}

.text-t-l,
.text-t-r,
.text-b-l,
.text-b-r {
    color: var(--bg-color);
    display: inline-flex;
    width: 18%;
    padding: 20px 10px;
    text-align: left;
    border-radius: 16px;
    justify-content: space-evenly;
    font-family: 'Open Sans', 'Regular';
    font-size: 27px;
    background: linear-gradient(to right, rgb(48, 47, 47), black);
    border: 1px solid;
}

.text-t-l {
    margin-left: 40rem;
    margin-top: 80px;
}

.text-t-r {
    margin-left: 67rem;
    margin-top: -106px;
    display: flex;
}

.text-b-l {
    margin-left: 40rem;
    margin-top: 50px;
}

.text-b-r {
    margin-left: 67rem;
    margin-top: -106px;
    display: flex;
}

/* vertical line */
.progress-bar {
    position: absolute;
    background-color: rgb(116, 116, 116);
    width: 10px;
    height: 170%;
    margin-left: 62%;
    margin-top: 15%;
    transform: translateX(-50%);
    border-radius: 10px;
    overflow: hidden;
}

.filled {
    width: 133%;
    background: #FE3908;
}

.stand-section-container{
   margin-right: 350px;

}

/* Stand gain section */
.stand-section {
    padding-top: 40px;
    padding-bottom: 90px;
    background: linear-gradient(to bottom right, rgb(46, 45, 45), rgb(0, 0, 0));
    margin-top: 70px;
}

.stand-head {
    color: var(--p-color);
    text-align: center;
    font-size: 45px;
    font-family: 'Open Sans', 'Regular';
}

/* journey */

.journey,
.analysis,
.rates,
.practices {
    display: block;
    position: relative;
    margin-left: 25rem;
    color: var(--p-color);
}



.journey h3,
.rates h3{
    font-family: 'Open Sans', 'Regular';
    font-size: 25px;
    font-weight: bold;

    
}

.analysis h3,
.practices h3{
    font-family: 'Open Sans', 'Regular';
    font-size: 25px;
    font-weight: bold;

}

.journey-head,
.rates-head{
    padding-top: 7rem;
    font-size: 25px;
    color: #fff;
    margin-top: -5px;
    width: 100%;
    font-weight: 600;
   
}

.analysis-head,
.practices-head {
    padding-top: 7rem;
    font-size: 25px;
    color: #fff;
    width: 100%;
    font-weight: 600; 
    text-align: right; 
}
.journey p,
.rates p {
    line-height: 30px;
    display: flex;
    font-family: 'Open Sans', 'Regular';
    font-size: 16px;
    color: var(--p-color);
    text-align: left;
}

.analysis p,
.practices p {
    line-height: 30px;
    /* display: inline-flex; */
    font-family: 'Open Sans', 'Regular';
    font-size: 16px;
    color: var(--p-color);
    text-align: right;
}

.journey-img,
.rates-img {
    margin-left: 72rem;
    margin-top: -18rem;
    display: flex;
}

/* analysis */



.analysis-img,
.practices-img {
    margin-left: 25rem;
    margin-top: -20rem;
    display: flex;
}
.analysis-img{
    margin-top: -250px;
}

/* rates */

.rates-img {
    margin-top: -280px;
}

/* practices */

.practices-img {
    margin-top: -300px;
}




/* process */

.process-section h1{
  text-align: left;
  margin-left: 180px;
  color: #fff;
  margin-top: 100px;
  font-size: 45px;
  font-family: 'Open Sans', 'Regular';
  font-weight: bold;
  padding-bottom: 40px;
}

.process-content-gif-wrap{
  display: flex;
    gap: 100px;
}

.process-content{
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.process-content-img-wrap{
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 30px;
  margin-left: 180px;
}
.process-conent1 h3{
    font-size: 25px;
    color: #fff;
    font-family: 'Open Sans', 'Regular';
    font-weight: bold;
}
.process-conent1 p{
  font-size: 16px;
  color: #fff;
  text-align: left;
  font-family: 'Open Sans', 'Regular';
  line-height: 30px;
}
.process-conent1  img{
  margin-top: 0px;
}


.stack-topic-heading,
.stack-topic-heading1,
.stack-topic-heading2,
.stack-topic-heading3{
    font-size: 22px;
    color: #FE780C;
}
.stack-ul,
.stack-ul1,
.stack-ul2,
.stack-ul3{
    font-size: 16px;
    margin-top: 10px;
    font-family: 'Open sans','Regular';
    color: #fff;
    font-weight: 400;
}


.vector1-div{
    position: absolute;
    top: 245%; 
    right: 85%; 
    transform: translate(-50%, -50%); 
    z-index: 1; 
    animation: rotate 4s linear infinite; 
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg); 
    }
    to {
      transform: rotate(360deg); 
  }
}
.vector2-div{
    position: absolute;
    top: 210%; 
    left: 20%; 
    animation: blink 1.5s infinite;
  }
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
 
 
 
.vector3-div{
    position: absolute;
    top: 265%; 
    right: 5%; 
    transform: translate(-50%, -50%); 
    z-index: 1; 
    animation: rotate 4s linear infinite; 
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg); 
    }
    to {
      transform: rotate(360deg); 
  }
}
.ogs-waves {
    position: relative;
    overflow: hidden;
}
.ogs-waves img {
    width: 100%;
    display: block;
    object-fit: cover;
    margin-top: 100px;
}

.servicedigitalmartec-footer{
  margin-right: 17px;
}

@media screen and (max-width: 768px) {
  .service-container{
    margin-top: 120px;
  }
    .service-head-text {
      font-size: 14px;
      margin-top: -120px;
      margin-left: 50px;
      margin-right: 80px;
     width: 72%;
     text-align: center;   
    }
  
    .service-img {
      margin-top: -50px;
    }
  
    .service-section {
      margin-left: 0;
      flex-direction: column;
    }
  
    .service-content {
      padding-left: 0;
   
    }
    .service-left img {
    display: none;
    }
    .left-text{
      display: none;

    }
    .hide-on-laptop11{
    display: none;

    }
    .right-text-hide{
      display: none;
    }
    .service-right {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      margin-top: 20px;
    }
  
    .service-right h3 {
      font-size: 22px;
      margin-left: 30px;
      color: #FE780C;
      margin-top: -20px;
    }
  
    .right-text {
      margin-top: 10px;
      margin-left: 20px;
      margin-right: 20px;
      text-align: center;
      line-height: 22px;
      font-size: 12px;
    }
  
    .stack-container {
      padding-left: 0;
    }
    .stack-container h1{
      font-size: 19px;
      margin-left: 30px;
      margin-right: 30px;
      color: #FE780C;
    }
    .stack-container p{
      font-size: 12px;
      margin-left: 30px;
      margin-right: 30px;
     
    }
  
    .stack-mainDiv {
      padding-left: 0;
      display: flex;
     row-gap: 30px;
   
    }
    .stack-topics{
      margin-left: 30px;
    }
    .stack-topics3{
      margin-right: 30px;
    }
    
  
    .stack-mainDiv h3 {
      margin-top: 20px;
      font-size: 14px;
     
    }
  
    .stack-mainDiv li {
      margin-top: 10px;
      font-size: 12px;
      margin-left: 20px;
      
    }
    .stand-section{
      width: 100%;
      display: none;
    }

   .process-section{
    padding-left: 10px;
   } 
    .process-section h1{
      font-size: 20px;
      margin-left: 5px;
     }
     .process-content-img{
       width: 20%;
     }
     .process-content-text h3{
      font-size: 22px;
     }
     .process-content-text p{
      font-size: 12px;
     }
  
     .gif-process {
    display: none;
    
    }
  
    .process-content-img-wrap {
      gap: 20px;
      margin-left: 0;
    }
  
    .vector1-div,
    .vector2-div,
    .vector3-div {
      display: none;
    }
    .ogs-waves{
      margin-top:10px;
    }
  }

  @media (min-width: 768px) and (max-width: 1366px)
  {
  .service-head-text
  {
   margin-left:220px;
   margin-top:-360px;
  }
  .right-text {
   width:700px;
  }
   
  .service-content{
    margin-top: -50px;
    margin-left:-200px;
  }
  .dmvideo
  {
    margin-left:-120px;
  }
  .progress-bar
  {
    margin-left:70%;
    height:200%;
    margin-top:15%;
  }
  .stand-section
  {
    margin-right:50px;
  }
  .stack-mainDiv
  {
    margin-right:100px;
  }
  .vector2-div
  {
    top:1380px;
    left:200px;
  }
  .vector1-div{
    margin-right:60px;
    margin-top:120px;
  }
  .vector3-div{
    margin-top:130px;
  }

}
@media  (min-width: 790px) and (max-width: 1100px)
{
  .service-right p br{
    display:none;
   }
  .right-text{
   
   line-height:30px;
   width:600px;
   align-items:center;
   justify-content: center;
 
  }
  .service-content
  {
    display:flex;
    flex-direction: column;
  }
  .service-right
  {
    margin-left:-450px;
    margin-top:500px;
  }
  .left-text{
    margin-top:650px;
    margin-left:0px;
  }
  .service-left
  {
    margin-left:80px;
  }
  .stack-container h1
  {
   font-size: 22px;
  }
  .stack-container p
  {
    font-size: 14px;
  }
  .stack-topics h3
  {
    font-size:16px;
  }
  .stack-ul li
  {
    font-size:12px;
  }
  .stack-topics1 h3
  {
    font-size:16px;
  }
  .stack-ul li
  {
    font-size:12px;
  }
  .stack-topics2 h3
  {
    font-size:16px;
  }
  .stack-ul1 li
  {
    font-size:12px;
  }
  .stack-topics h3
  {
    font-size:16px;
  }
  .stack-ul2 li
  {
    font-size:12px;
  }
  .stack-topics3  h3
  {
    font-size:12px;
  }
  .stack-ul3 li
  {
    font-size:12px;
  }
  .stack-mainDiv
  {
    margin-right:-0px;
  }
  .progress-bar
  {
    display: none;
  }
  .journey-head
  {
    margin-left:400px;
  }
  .journey-text
  {
    margin-left:400px;
  }
  .journey-img{
    position: relative;
  margin-top:100px;
  margin-right:300px;
  gap:10px;
  }
  .rates-head
  {
    margin-left:400px;
  }
  .rates-text
  {
    margin-left:400px;
  }
  .rates-img{
    position: relative;
  margin-top:100px;
  margin-right:350px;
  }
  .practices-head
  {
    margin-left:-350px;
  }
  .practices-text
  {
    margin-right:350px;
  }
  .practices-img{
    position: relative;
  margin-top:100px;
  margin-left:784px;
  }
  .analysis-head
  {
    margin-left:-350px;
  }
  .analysis-text
  {
    margin-right:350px;
  }
  .analysis-img{
    position: relative;
  margin-top:100px;
  margin-left:784px;
  }
  .process-conent1
  {
    margin-left:-150px;
  }
  .process-content-text p br
  {
    display:none;
  }
  .process-content-text p br
  {
    display:none;
  }
  .dmvideo
  {
    margin-left:-100px;
  }
  .service-head-text
  {
    margin-bottom:-20px;
    margin-left:50px;
  }
  .service-head-section h1 br
  {
    display:none;
  }
  .service-container img
  {
    margin-top:50px;
  }
  .process-section h1{
   margin-left:60px;
   width:500px;
  }

}