.success-stories-img-section img {
    margin-top: 0px;
    position: relative;
    width: 100vw;
    object-fit: cover; 
    inset: 0;
    filter: brightness(55%);
    z-index: -1;
  }
 
  
  
.success-stories-img-text h1{
   position: absolute;
   top: 300px; 
   text-align: center;
   font-size: 55px;
   left:160px;
   font-family: 'Open Sans', 'Regular';
   font-weight: bolder;
   width: 80%;
}

.success-stories-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding-top: 70px; 
}

.ss_cs_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0px;
  width: 100%;
}

.ss_cs_line1 {
    flex: 0 1 calc(50% - 0px);
    flex-direction: column;
    align-items: left;
    padding-left: 120px;
 
}

.ss_cs_line1 img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.ss_cs_line1 h3 {
  font-family: 'Open Sans', 'Regular';
  font-size: 25px;
  margin-bottom: 10px;
}

.ss_cs_line1 p {
  font-family: 'Open Sans', 'Regular';
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 30px;
  text-align: left;
  width: 80%;
}


.ss_cs_line1 button::after {
  content: "";
  display: inline-block;
  width: 10px; 
  height: 10px;
  background: url('../../Careers/careers-assests/carrow2.png') no-repeat center center;
  background-size: contain;
  margin-left: 10px; 
  opacity: 0; /* Hide the arrow initially */
  transform: translateX(-10px); /* Position it off-screen initially */
  transition: opacity 0.3s, transform 0.3s; /* Smooth transition for opacity and transform */
}

.ss_cs_line1 button:hover::after {
  opacity: 1; /* Show the arrow on hover */
  transform: translateX(0); /* Slide the arrow into place on hover */
}

.ss_cs_line1 button {
  background: none;
  border: none;
  color: inherit; 
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
  padding-bottom: 70px;
}

.ss_cs_line1 button:hover {
  color: #FE780C; 
}

.ss_wave
{
img{width:100%}
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .success-stories-img-section img{
    padding-top: 76px; 
}
.success-stories-img-text h1 {
    font-size: 22px;
    left: 50px;
   margin-top: -150px;

}
  .success-stories-container {
    padding-top: 50px;
  }

  .ss_cs_section {
    flex-direction: row;
    gap: 20px;
  }

  .ss_cs_line1 {
    flex: 1 1 100%;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }

  .ss_cs_line1 h3 {
    font-size: 20px;
    width: 90%;
    margin-left: 10px;
  }

  .ss_cs_line1 p {
    font-size: 14px;
    width: 101%;
    margin-left: 10px;
  }

  .ss_cs_line1 button {
    padding-bottom: 50px;
  }

  .ss_wave img {
    width: 100%;
  }
  .ss_cs_line1 button{
    margin-top: 5px;
    margin-left: 13px;
   
  }
}
