.servicespage-one-wrapper {
    width: 100vw; 
  }

  
  .servicespage-one-img-section img {
    margin-top: -100px;
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    inset: 0;
    /* filter: grayscale(100%); */
    z-index: -1;
  }

  
  .servicespage-one-container {
    margin-top: 100px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;  
    }

.servicespage-one-head-text h1{
      position: absolute;
      top: 410px;
      left: 50%;
      width: 50%;
      transform: translate(-50%, -50%);
      font-size: 45px;
      font-family: 'Open Sans', 'Regular';
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: bolder;
  }

  @media screen and (max-width: 768px) {
    
    /* .servicespage-one-wrapper{
      min-height: calc(100vh - 100px);
    } */
    .servicespage-one-img-section img {
      margin-top: 0;
      position: static;
      width: 100%;
      height: 50vh;
      object-fit: cover; 
      z-index: -1;
    }
    .servicespage-one-container {
      margin-top: 50px;
    }
    .servicespage-one-head-text h1 {
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 22px;
      width: 50%;
    }
    
  }
  @media screen and (min-width: 1366px) {
    .servicespage-one-head-text h1{
    margin-top: -50px;
  }
}

@media(min-width:790px) and (max-width: 1200px) {
  .servicespage-one-head-text h1{
    margin-top: -150px;
  }
  .servicespage-one-img-section
  {
    margin-top:100px
  }
}

