.resources-library-img-section img {
    margin-top: 0px;
    position: relative;
    width: 100vw;
    object-fit: cover; 
    inset: 0;
    filter: brightness(55%);
    z-index: -1;
  }
 
  
  
.resources-library-img-text h1{
   position: absolute;
   top: 300px; 
   text-align: center;
   font-size: 55px;
   left:160px;
   font-family: 'Open Sans', 'Regular';
   font-weight: bolder;
   width: 80%;
}

.resources-library-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center tiles horizontally */
  gap: 20px; /* Space between tiles */
  padding-top: 160px; 
}

.rl_cs_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center tiles horizontally */
  gap: 100px; /* Space between tiles */
  width: 100%;
}

.rl_cs_line1 {
  flex: 0 1 calc(40% - 120px);
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to bottom, #333333, #0D0D0D);
  padding: 30px 0px 30px 30px; 
  border-radius: 10px; 
  margin-bottom: 20px; 
  text-align: left;
  transition: box-shadow 0.3s ease;;
}

.rl_cs_line1:hover {
  box-shadow: 0 0 0 1px #ffffff; /* White border on hover */
}

.rl_cs_line1 img {
  max-width: 100%;
  height: auto;
  margin-bottom: 5px;
}

.rl_cs_line1 h3 {
  font-family: 'Open Sans', 'Regular';
  font-size: 25px;
  margin-bottom: 10px;
 width: 80%;
 padding: 10px 0px;
}

.rl_cs_line1 button::after {
  content: "";
  display: inline-block;
  width: 10px; 
  height: 10px;
  background: url('../../Careers/careers-assests/carrow2.png') no-repeat center center;
  background-size: contain;
  margin-left: 10px; 
  opacity: 0; /* Hide the arrow initially */
  transform: translateX(-10px); /* Position it off-screen initially */
  transition: opacity 0.3s, transform 0.3s; /* Smooth transition for opacity and transform */
}

.rl_cs_line1 button:hover::after {
  opacity: 1; /* Show the arrow on hover */
  transform: translateX(0); /* Slide the arrow into place on hover */
}

.rl_cs_line1 button {
  background: none;
  border: none;
  color: inherit; 
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
}

.rl_cs_line1 button:hover {
  color: #FE780C; 
}

.rl_wave
{
img{width:100%}
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .resources-library-img-section img {
    width: 100%;
    top:60px;
   
  }

  .resources-library-img-text h1 {
    top: 125px;
    font-size: 22px;
    left: 10px;
    width: 90%;
    text-align: center;
  }

  .resources-library-container {
    margin: 50px;
    
  }

  .rl_cs_section {
    flex-direction: column;
    gap: 20px;
  }

  .rl_cs_line1 {
    flex: 1 1 0%;
    padding: 20px;
    text-align: center;
  }

  .rl_cs_line1 img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .rl_cs_line1 h3 {
    font-size: 22px;
    text-align: left;
   
  }

  .rl_cs_line1 button {
    margin-top: -120px;
    padding-bottom: 20px;
  }

  .rl_wave img {
    width: 100%;
  }
}

