.hform-ls {
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  padding-left: 70px;
  place-items: start;
}

.hform-ls h1 {
  color: #fff;
  font-family: Open Sans, Regular;
  font-size: 35px;
  line-height: 1px;
  margin-bottom: 60px;
  width: auto;

}

.hform-ls p {
  color: white;
  font-size: 16px;
  font-family: "Open Sans", "Regular";
  text-align: left;
  line-height: 30px;
}

.hform-ls-icons {
  margin-top: 60px;
}

.hform-ls-icons div {
  display: flex;
  align-items: center;
}

.hform-ls-icons img {
  margin-right: 10px;
}

.hform-ls-icons p {
  margin: 15px;
}

.hright {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.hright-content {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 550px;
  padding: 20px;
  gap: 40px;
  font-family: "Open Sans", "Regular";
}

.hinput-container {
  margin-bottom: 35px;
}

input {
  border: 2px solid grey;
  border-radius: 5px;
  color: #fff;
  margin: 10px 0 15px;
  padding: 10px;

}

textarea {
  border: none;
  border-bottom: 2px solid #333333;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  color: #ffffff;
  resize: none;
  font-family: "Open Sans", "Regular";
  padding: 10px 10px;
}

.hinput-container-textarea {
  margin-bottom: 50px;
  display: flex;
  width: 100%;
  align-items: flex-end;
}

::placeholder {
  color: #999;
}

input:focus,
input:hover,
textarea:hover {
  border-bottom-color: #ffffff;
}

input:focus+label,
input:not(:placeholder-shown)+label {
  font-size: 12px;
  color: white;
}

.hformButton {
  padding: 15px 70px;
  border-radius: 30px;
  background-color: white;
  border: 2px solid grey;
  display: block !important;
  cursor: pointer;

}

.hformButton em {
  font-size: 16px;
  font-family: "Open Sans", "Regular";
  font-style: normal;
}

.hformButton:hover {
  background-color: transparent;
  color: white;
}