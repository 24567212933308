.cmp-content-highlight__section {
    background-color: #000;
    color: #fff;
    padding: clamp(15px, 2vw, 30px);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    gap: clamp(20px, 4vw, 40px);
}

.cmp-content-highlight__heading {
    font-family: 'Open Sans', sans-serif;
    flex: 1 1 100%;
    order: -1;
}

.cmp-content-highlight__heading h3 {
    font-family: 'Open Sans', sans-serif;
    font-size: clamp(0.875rem, 2vw, 1.34rem);
    font-weight: bold;
    margin-bottom: clamp(0.625rem, 2vw, 1.25rem);
    line-height: 1.6;
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
}

@media (min-width:768px) {
    .cmp-content-highlight__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 30px;
        position: relative;
        left: -14%;
    }
}

@media (min-width:1024px) {
    .cmp-content-highlight__wrapper {
        display: flex
        ;
                flex-direction: row;
                gap: 30px;
                justify-content: center;
                left: clamp(-8%, -6%, -10%);
                left: -14%;
                margin-top: -40px;
                position: relative;
    }
}

.cmp-content-highlight__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
}

.cmp-content-highlight__image img {
    width: clamp(40%, 66%, 40%);
    height: auto;
    border-radius: 8px;
    margin: 0 auto;
    margin-right: 2px;
}
@media (min-width: 1024px) {
    .cmp-content-highlight__heading h3 {
        align-items: center;
        display: flex
;
        font-family: Open Sans, sans-serif;
        font-size: 15px;
        justify-content: center;
        left: 17%;
        margin-top: 20px;
        position: relative;
        text-align: start;
        width: 500px;
    }
}

.cmp-content-highlight__points {
    font-family: 'Open Sans', sans-serif;
    display: flex;
    flex-direction: row-reverse;
    gap: clamp(0.625rem, 1.5vw, 1.25rem);
    margin-top: clamp(0rem, -35vw, -10.125rem);
    margin-left: clamp(0rem, 20px, 20px);

}

.cmp-content-highlight__points ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
}

.cmp-content-highlight__points li {
    display: flex;
    align-items: center;
    color: #fff;
    position: relative;
    padding-left: clamp(15px, 2vw, 25px);
    font-size: clamp(0.625rem, 1.2vw, 1.34rem);
    line-height: 1.5;
    margin-top: clamp(0.625rem, 1.3vw, 1.30rem);
}

.cmp-content-highlight__points li::before {
    content: "•";
    color: #ff5722;
    font-size: clamp(0.75rem, 2vw, 1.125rem);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

@media (min-width: 768px) {
    .cmp-content-highlight__points {
        font-family: 'Open Sans', sans-serif;
        flex-direction: row;
        margin-top: 1rem;
        margin-left: 0;
        gap: 1rem;
    }
}

@media (min-width: 1024px) {
    .cmp-content-highlight__points {
        font-family: 'Open Sans', sans-serif;
        flex-direction: row;
        margin-left: clamp(1rem, 3vw, 1.5rem);
        gap: clamp(1rem, 2vw, 1.5rem);


    }
}