.platform-dataiku-wrapper {
    width: 100vw; 
  }


  .platform-dataiku-img-section img {
    margin-top: -100px;
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover; 
    inset: 0;
    /* filter: grayscale(100%); */
    filter: brightness(55%);
    z-index: -1;
  }

  .pdiku-img-text h1{
    position: absolute;
    top: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    font-size: 55px;
    font-family: 'Open Sans', 'Regular';
    font-weight: bold;
    text-align: center;
}


.pdiku-partner-section {
  margin-top: 50px;
  display: flex;
  align-items: center; 
  margin-left: 250px ;
  gap: 100px;
}

.pdiku-partner-section img {
  padding-top: 35px;

}

.pdiku-partner-text h3 {
  font-size: 22px;
  font-family: sans-serif;
  font-weight: bold;
  text-align: left;
  line-height: 30px;
  margin-bottom: 20px;

  
}

.pdiku-partner-text p  {
  font-size: 18px;
  font-family: sans-serif;
  text-align: justify;
  line-height: 30px;
}

.pdiku-services-section{
  margin-top: 80px;
}
.pdiku-services-heading h1
{
    color: white;
    font-size: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
   font-family: sans-serif;
    font-weight: bold;
}

.pdiku-services-boxes{
  display: flex; 
  flex-direction: column;
  gap: -30px;
  margin-top: 50px;
  }

.pdiku-services-BoxUp
{
  display: flex;
  gap:20px;
  padding-top: 30px;
  justify-content: center;
  align-items: center;
}
.pdiku-services-Box1,
.pdiku-services-Box2,
.pdiku-services-Box3
{
  display:flex;
  padding-top: 40px;
  width: 380px; 
  height:420px;
  padding-left: 50px;
  border-radius: 10px;
  flex-direction: column;
  background: linear-gradient(to bottom, #333333 ,  #0D0D0D); 
}

.pdiku-services-Box1 h3,
.pdiku-services-Box2 h3,
.pdiku-services-Box3 h3
{
  color: #fff;
  display: flex;
  justify-content: left;
  font-size: 22px;
  font-weight:500;
  line-height: 1.2; 
  word-break: break-word; 
  font-family: sans-serif;
}

.pdiku-services-Box1 p,
.pdiku-services-Box2 p,
.pdiku-services-Box3 p
{
  color:white;
  text-align: justify;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 30px;
  padding-top: 20px;
}



.pdiku-services-BoxDown
{
  display: flex;
  gap:20px;
  padding-top: 30px;
  justify-content: center;
}
.pdiku-services-Box4,
.pdiku-services-Box5
{
  display:flex;
  padding-top: 40px;
  width: 380px; 
  height:420px;
  padding-left: 50px;
  border-radius: 10px;
  flex-direction: column;
  background: linear-gradient(to bottom, #333333 ,  #0D0D0D); 
}

.pdiku-services-Box4 h3,
.pdiku-services-Box5 h3
{
  color: #fff;
  display: flex;
  justify-content: left;
  font-size: 22px;
  font-weight:500;
  line-height: 1.2; 
  word-break: break-word;
  font-family: sans-serif;
}

.pdiku-services-Box4 p,
.pdiku-services-Box5 p
{
  color:white;
  text-align: justify;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 30px;
  padding-top: 20px;

}

@keyframes rotate {
  from {
    transform: rotate(0deg); 
  }
  to {
    transform: rotate(360deg); 
}
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.pdiku-services-blinkystuff1{
  position: absolute;
  top: 162%; 
  right: 200px;
  animation: blink 1.5s infinite;
}

.pdiku-services-blinkystuff2{
  position: absolute;
  top: 255%; 
  left: 180px;
  animation: blink 1.5s infinite;

}

.pdiku-services-globe{
  position: absolute;
  top: 295%; 
  right: 8%; 
  transform: translate(-50%, -50%); 
  z-index: 1; 
  animation: rotate 4s linear infinite; 
}

.pdiku-services-blinkystuff3{
  position: absolute;
  top: 390%; 
  right: 100px;
  animation: blink 1.5s infinite;
}

.pdiku-services-blinkystuff4{
  position: absolute;
  top: 355%; 
  right: 430px;
  animation: blink 1.5s infinite;

}

.pdiku-services-load{
  position: absolute;
  top: 372%; 
  left: 13%; 
  transform: translate(-50%, -50%); 
  z-index: 1; 
  animation: rotate 4s linear infinite; 
}


.pdiku-client-centeric-section{
  margin-top: 50px;
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center; 
  gap: 110px;
  background: linear-gradient(to right, #333333 ,  #0D0D0D); 
 
}

.pdiku-client-centeric-content h1{
  font-size: 45px;
  font-family: sans-serif;
  text-align: left;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-left: 190px;

}


.pdiku-client-centeric-content p{
  font-size: 18px;
  font-family:sans-serif;
  text-align: left;
  line-height: 30px;
  width: 75%;
  margin-left: 190px;

}
.pdiku-client-centeric-section img{
  margin-right: 200px;
}

.pdiku-differentiators-head h1{
  display: flex;
  justify-content: center;
  font-size: 45px ;
  font-family: 'Open Sans', 'Regular';
  text-align: center;
  font-weight: bold;
  margin-top: 60px;
  margin-bottom: 70px;

}


/* Animation of Scroll */

.pdiku-differentiators-imagescroll{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
}


.pdiku-animate-on-scroll {
  opacity: 0;
  transition: opacity 5s, transform 5s;
}

.pdiku-animate {
  opacity: 1;
  transform: translateY(0);
}


.pdiku-differentiators-imagescroll {
  padding-left: 230px;
}

.pdiku-image-text-container1 {
 display: flex;
 gap: 100px;
}

.pdiku-image-text-container2 {
  display: flex;
  gap: 100px;
 }

 .pdiku-image-text-container3 {
  display: flex;
  gap: 100px;
 }

 .pdiku-image-text-container4 {
  display: flex;
  gap: 100px;
 }

 .pdiku-image-text-container5 {
  display: flex;
  gap: 100px;
 }

.pdiku-images1animation, 
.pdiku-images2animation, 
.pdiku-images3animation, 
.pdiku-images4animation, 
.pdiku-images5animation {
 padding-bottom: 70px;
}

.pdiku-text-animation  {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  
}

.pdiku-text-animation  h1 {
  font-size: 45px;
  font-family: sans-serif;
  text-align: left;
  font-weight: bold;
  margin-top: 90px;
  line-height: 1.2;
  word-break: break-word;
  background: linear-gradient(to bottom, #FE780C, #FE3908);
  -webkit-background-clip: text;
  color: transparent;
      
}

.pdiku-text-animation  h3 {
  font-size: 25px;
  font-family: sans-serif;
  text-align: left;
  font-weight: bold;
  line-height: 2; 
  word-break: break-word;
     
}


.pdiku-text-animation  p {
  font-size: 18px;
  font-family: sans-serif;
  text-align: left;

}

.pdiku-wave {
  position: relative;
  overflow: hidden;
}
.pdiku-wave img {
  width: 100%;
  display: block;
  object-fit: cover;
}

.pdiku-footer{
  margin-right: 17px;
}
@media (max-width: 768px) {
  .platform-dataiku-img-section img {
    margin-top: 100px;
    filter: brightness(100%);
  }
  .pdiku-img-text h1 {
    top: 200px;
    font-size: 22px;
  }
  .pdiku-partner-section {
    margin-left: 0;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  .pdiku-partner-section img {
    padding-top: 0;
    width: 80%;
  }
  .pdiku-partner-text h3 {
    font-size: 20px;
    line-height: 25px;
    margin-left: 60px;
    color:#FE780C;
  }
  .pdiku-partner-text p {
    display: none;
  }
  .hide-on-laptop5{
    font-size: 12px;
    font-family: 'Open Sans', 'Regular';
    margin-left: 20px;
    margin-right: 20px;
    margin-top: -20px;
  }
  .pdiku-services-heading h1 {
    font-size: 22px;
   margin-top: -50px;
  }
  .pdiku-services-boxes {
    gap: 20px;
    margin-top: -20px;
  }
  .pdiku-services-BoxUp,
  .pdiku-services-BoxDown {
    flex-direction: column;
    align-items: center;
  }
  .pdiku-services-Box1,
  .pdiku-services-Box2,
  .pdiku-services-Box3,
  .pdiku-services-Box4,
  .pdiku-services-Box5 {
    width: 90%;
    height: auto;
    padding: 20px;
  }
  .pdiku-services-Box1 h3,
  .pdiku-services-Box2 h3,
  .pdiku-services-Box3 h3,
  .pdiku-services-Box4 h3,
  .pdiku-services-Box5 h3 {
    font-size: 20px;
    line-height: 25px;
  }
  .pdiku-services-Box1 p,
  .pdiku-services-Box2 p,
  .pdiku-services-Box3 p,
  .pdiku-services-Box4 p,
  .pdiku-services-Box5 p {
    font-size: 16px;
    line-height: 22px;
  }
  .pdiku-services-blinkystuff1,
  .pdiku-services-blinkystuff2,
  .pdiku-services-blinkystuff3,
  .pdiku-services-blinkystuff4 {
    display: none;
  }
  .pdiku-services-globe,
  .pdiku-services-load {
    display: none;
  }
  .pdiku-client-centeric-section {
    height: auto;
    flex-direction: column;
    gap: 20px;
  }
  .pdiku-client-centeric-content h1 {
    font-size: 22px;
    margin-left: 40px;
  }
  .pdiku-client-centeric-content p {
    font-size: 12px;
    line-height: 22px;
    width: 100%;
    margin-left: 10px;
  
  }
  .pdiku-client-centeric-section img {
    margin-right: 0;
    width: 90%;
  }
  .pdiku-differentiators-head h1 {
    font-size: 22px;
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .pdiku-differentiators-imagescroll {
    padding-left: 0;
  }
  .pdiku-image-text-container1,
  .pdiku-image-text-container2,
  .pdiku-image-text-container3,
  .pdiku-image-text-container4,
  .pdiku-image-text-container5 {
    flex-direction: column;
    gap: 20px;
  }
  .pdiku-images1animation img,
  .pdiku-images2animation img,
  .pdiku-images3animation img,
  .pdiku-images4animation img,
  .pdiku-images5animation img{
    padding-bottom: 10px;
    width: 90%;
    margin-left: 20px;
    margin-top: 10px;
  }

  .pdiku-text-animation h1 {
    font-size: 30px;
    margin-top: 40px;
    margin-top: -50px;
    margin-left: 20px;
  }
  .pdiku-text-animation h3 {
    font-size: 20px;
    line-height: 25px;
    margin-left: 20px;
  }
  .pdiku-text-animation p {
    font-size: 12px;
    line-height: 22px;
    margin-left: 20px;
  }
  .pdiku-wave img {
    width: 100%;
    height: auto;
    margin-top: 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .hide-on-laptop5 {
    display: none; /* Hide the content on screens with a width of 1024 pixels or larger (laptops and desktops) */
  }
}
@media (min-width: 768px) and (max-width: 1366px)
{
  .pdiku-img-text h1
  {
    margin-top:-80px;
  }
  .pdiku-partner-section{
    margin-left: 150px;
  }
  .pdiku-services-blinkystuff2 
  {
    margin-top:50px;
  }
  .pdiku-client-centeric-content h1{
 line-height: 50px;
  }
  .pdiku-differentiators-imagescroll
  {
    margin-left:-50px;
  }
}




