.form-home {
  display: flex;
  background: linear-gradient(to right, #302f2f, #0d0d0d);
  font-family: "Open-Sans", "Regular";
  padding: 50px 80px 50px 80px;
  justify-content: space-between;
}

/* Media Query for responsiveness */

.hform-ls {
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  padding-left: 70px;
}
.hform-ls h1 {
  font-size: 55px;
  font-weight: 600;
  line-height: 1px;
  color: white;
  margin-bottom: 60px;
  width: 400px;
  font-family: "Open Sans", "Regular";
}

.hform-ls p {
  color: white;
  font-size: 16px;
  font-family: "Open Sans", "Regular";
  text-align: left;
  line-height: 30px;
}

.hform-ls-icons {
  margin-top: 60px;
}

.hform-ls-icons div {
  display: flex;
  align-items: center;
}

.hform-ls-icons img {
  margin-right: 10px;
}

.hform-ls-icons p {
  margin: 15px;
}

.hright {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.hright-content {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 550px;
  padding: 20px;
  gap: 40px;
  font-family: "Open Sans", "Regular";
}

.hinput-container {
  margin-bottom: 35px;
}

input {
  border: none;
  border-bottom: 2px solid #333333;
  outline: none;
  width: 100%; /* Adjusted for mobile responsiveness */
  padding: 8px;
  box-sizing: border-box;
  background-color: transparent;
  color: #ffffff;
  font-family: "Open Sans", "Regular";
  /* -moz-appearance: textfield; */
}

textarea {
  border: none;
  border-bottom: 2px solid #333333;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  color: #ffffff;
  resize: none;
  font-family: "Open Sans", "Regular";
  padding: 10px 10px; 
}

.hinput-container-textarea {
  /* padding-top: 50px; */
  margin-bottom: 50px;
  display: flex;
  width: 100%;
  align-items: flex-end;
}

::placeholder {
  color: #999;
}

input:focus,
input:hover,
textarea:hover {
  border-bottom-color: #ffffff;
}

input:focus + label,
input:not(:placeholder-shown) + label {
  font-size: 12px;
  color: white;
}

.hformButton {
  padding: 15px 70px;
  border-radius: 30px;
  background-color: white;
  border: 2px solid grey;
  display: block !important;
  cursor: pointer;
  
}

.hformButton em{
  font-size: 16px;
  font-family: "Open Sans", "Regular";
  font-style: normal;
}

.hformButton:hover {
  background-color: transparent;
  color: white;
}


@media (max-width: 768px) {
  .form-home {
    padding: 50px 50px 0 50px;
    flex-direction: column;
    /* margin-top: 100px; */
    margin-left:-70px ;
   
  }
  .hright-content {
    padding: 0;
    width: 100%;
    margin-top: 30px;
    margin-left: 30px;
  }
  .hform-ls h1 {
    font-size: 35px;
    line-height: 1px;
    color: white;
    margin-bottom: 60px;
    width: 400px;
   
    font-family: "Open Sans", "Regular";
  }

  .hform-ls p br{
    display: none;
  }

  .hright{
    margin-bottom: 20px;
  }

  .hformButton {
    padding: 15px 70px;
    border-radius: 30px;
    background-color: white;
    border: 2px solid grey;
    color: black;
    display: block !important;
    cursor: pointer;
    font-family: "Open Sans", "Regular";
  }

  
  .hformButton:hover {
    background-color: transparent;
    color: white;
  }

  .hformButton em{
    font-size: 16px;
    font-family: "Open Sans", "Regular";
    font-style: normal;
  }



  .hinput-container-textarea{
    margin-top: 0px;
    margin-bottom: 50px;

  }
  .hformButton{
    margin-top: 0px;
    margin-bottom: 50px;
  }
  
  
}
