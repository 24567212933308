body {
  font-family: 'Open Sans', sans-serif;
  background-color: black;
  color: white;
  margin: 0;
  padding: 0;
}
 
.video-page-nav {
  margin-bottom: 20px;
}
 

.video-title-headig{
  text-align: center;
}
.video-content{
  text-align: center;
}
.video-webinar{
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}
.video-webinar video{
width: 50%;
margin-left: 250px;
}
.download-btn button{
  background-color:transparent;
  margin-bottom: 40px;
  color: white;
}
.webinar-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
}



 
.video-tag-filters-section {
  margin-bottom: 30px;
  background-color: #2B2B2B;
}
 
.video-tag-filters-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding:6px 10px;
  margin-top: -18.8px;
  font-size: 16px;

  background-color: #1a1a1a;
  color: white;
}
 
.video-tag-filters-wrapper h2 {
  font-size: 16px;
  margin-right: 20px;
  margin-left:120px;
  color:white;
  text-align: left;
  min-width: 80px;
}
 
.video-tag-filters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
 
.video-tag-filter-button {
  border: 2px solid #1a1a1a;

  padding:10px 20px;
  border-radius: 5px;
  margin-right: 50px;
  background-color: #1a1a1a;
  color: gray;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease, border-color 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
 
.video-tag-filter-button.active-filter {
  border: 2px solid white;
}
 
.video-tag-filter-button p {
  
  font-weight: 600;
  text-align: center;

  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: grey;
  transition: color 0.3s ease;
  box-sizing: border-box;
}
 
.video-tag-filter-button:hover {
  transform: scale(1.1);
  background-color: #1a1a1a;
  color: white;
}
 
.video-tag-filter-button p:hover {
  color: white;
}
 
.video-tag-filter-button:focus {
  outline: none;
  transform: scale(1.1);
}
 
.video-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  margin: 0 auto;
  gap: 70px;
  padding-bottom: 40px;
  width: 80%;
}
.video-item-wrapper{
  display: flex;
  justify-content: center;
   align-items: center;
   
}

 
.video-items-description {
  color: #FF7F00;
}

 
.video-item video,
.video-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: 1px solid white;
  transition: transform 0.3s ease-in, box-shadow 0.3s ease-in;

}

.video-item h3 {
  font-family: 'Open Sans', sans-serif;
  margin-top: 10px;
  font-size: 1.2rem;
  color: #FF7F00;
}
 
.video-item p {
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
  color: #cccccc;
}
 
.video-container {
  position: relative;
}
 
.videos-load-more-section {
  text-align: center;
  margin-top: 40px;
}
 
.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  
}
 
.play-button-icon {
  color: gray;
  font-size: 40px;
  position: relative;
}
.play-button-icon:hover {
  color: #FF7F00;
  transform: scale(1.02);
}

.videos-load-more-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
 
.videos-load-more-button {
  padding: 15px 45px;
  border-radius: 25px;
  background-color: #1a1a1a;
  color: white;
  font-weight: 600;
  border: 1px solid white;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: relative;
}
 
.videos-load-more-button:hover {
  border-color: #FE780C;
}
 
.videos-load-more-button:hover::after {
  content: url('./../../Careers/careers-assests/carrow2.png');
  position: absolute;
  right: 10px;
  top: 55%;
  transform: translateY(-50%);
  width: 20px;
}
 
@media screen and (min-width: 300px) and (max-width: 767px) {

 
  .video-tag-filters-wrapper {
   
    padding: 15px;
  }
 
  .video-tag-filters-wrapper h2 {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .video-tag-dropdown{
    background: linear-gradient(to right, #302f2f, #0d0d0d);
    color:white;
    text-align: center;
    border-radius: 5px;
    padding: 8px;
    width: 80%;
    font-size: 14px;
    -webkit-appearance: none; 
  -moz-appearance: none; 
  appearance: none; 
 
  }
  .video-tag-dropdown option{
    background-color: #302f2f;
    color:white;
  }
  .video-tag-dropdown::after{
    border-color: none;
    padding-right: -12px;
  }
 
 
  .video-items {
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0;
    width: 100%;
    gap: 40px;
    padding: 0 10px;
  }
 
  .video-item h3 {
    font-size: 1rem;
  }
 
  .video-item p {
    font-size: 14px;
  }
 
  .videos-load-more-button {
    margin-left: 0px;
    padding: 12px 30px;
    font-size: 14px;
    margin-top: 40px;
  }
 
  .video-item video,
  .video-item img {
    width: 100%;
    height: auto;
  }


  .play-button-icon:hover {
    color: #FF7F00;
    transform: scale(1.02);
  }
  .hfooterl2-services_about h3{
    font-size: 22px;
  }
  .hfooterl2-services_about{
    margin-bottom:10px;
  }
}
 
@media screen and (min-width: 768px) and (max-width: 1024px) {

 
  .video-tag-filter-button p {
    padding-right:10px;
  }
  .video-tag-filters-wrapper {
    flex-direction: row;
    align-items: center;
    padding: 20px;
  }
 
  .video-tag-filters-wrapper h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
 
  .video-items {
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0;
    width: 60%;
    gap: 30px;
    margin-left: 100px;
    padding: 0 15px;
  }
 
  .video-item h3 {
    font-size: 1.25rem;
  }
 
  .video-item p {
    font-size: 16px;
  }
 
  .videos-load-more-button {
    margin-left: auto;
    margin-right: auto;
    padding: 14px 35px;
    font-size: 16px;
  }
  .videos-load-more-section{
    padding-bottom: 50px;
    padding-top:50px;
  }
  .video-item video,
  .video-item img {
    width: 100%;
    height: auto;
  }
  .hfooterl2{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 20px 20px 20px;
  }
  .hfooterl2-services_about{
      display: flex;
      margin-top: 200px;
      margin-left: -50px;
      gap: 120px;
  }
  .hfooterl2-Platform_Resources{
    display: flex;
    margin-top:-40px;
    margin-left: -50px;
    gap: 270px;
  }
  .hfooterl2-Policies{
    display: flex;
    margin-top: -30px;
    margin-left: -50px;
    gap: 130px;
  }
  .hfooterl2-services_about p{
    font-size: 18px;
  }
  .hfooterl2-Platform_Resources p{
    
    
    font-size: 18px;
  }
  .hfooterl2-Policies p{
    font-size:18px;
  }
}
 
/* Webinar component */
.webinar_wrapper{
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  height: 869px;
 }
 .webinar{
  font-family: 'Open Sans', sans-serif;
  width:900px;
  text-align: center;
 }
 .webinar p{
  font-size: 14px;
  margin-bottom: 13px;
  font-family: 'Open Sans', sans-serif;

}
.webinar_video{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:60px;
  margin:20px 0px 60px 0px;
}
.webinar_video video{
  width:550px;
  height:240px;
}
.webinar_video--contents{
  font-family: 'Open Sans', sans-serif;
  width:520px;
  display:flex;
  flex-direction: column;
  margin-top: -45px;
  align-content:center;
  justify-items: center;
}

.webinar_video--title {
  margin:12px 0px;
  width:535px;
}
.webinar_technical-content{
  font-family: 'Open Sans', sans-serif;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  width:900px;
  text-align: center;
  margin-top: 36px;
}
.webinar_technical-content--title{
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;

}
.webinar_technical-content--para{
  margin-bottom: 0px;
  margin-top: 20px;
  font-size: 14px;

}
.webinar_video--text p{
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
  width: 108%;

}
.webinar_video--text a{
  background: none;
  border: none;
  color: inherit; 
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
  padding-bottom: 70px;
  color: white;
  margin-top: 20px;
}
.webinar_video--text a::after{
  content: "";
  display: inline-block;
  width: 10px; 
  height: 10px;
  background: url('../../Careers/careers-assests/carrow2.png') no-repeat center center;
  background-size: contain;
  margin-left: 10px; 
  opacity: 0;
  transform: translateX(-10px); 
  transition: opacity 0.3s, transform 0.3s;
  }
    
  .webinar_video--text a:hover{
    color: #FF7F00;

  }
  .webinar_video--text a:hover::after{
    opacity: 1;
    transform: translateX(0);

  }
  
  .webinar_video--playbutton{
    width:20px;
    text-align: center;
    position:relative;
    left:-340px;
    bottom:-120px;
    cursor: pointer;
    margin: 0px;
    color: gray;
    font-size: 30px;
  }
  .webinar_video--playbutton:hover {
    color: #FF7F00;
    transform: scale(1.02);
  }
  .webinar_video--pausebutton{
    width:20px;
    text-align: center;
    position:relative;
    left:-340px;
    bottom:-120px;
    cursor: pointer;
    margin: 0px;
    color: gray;
    font-size: 30px;

  }
  .webinar_video--pausebutton:hover{
    color: #FF7F00;
    transform: scale(1.02);
  }

  @media screen and (min-width: 300px) and (max-width: 767px) {
  
    .webinar-wrapper{
      width:100%;
    }
    .webinar{
      margin-top: 200px;
  }
    .webinar_video--descriptions h1{
      text-align: center;
      width:900px;
      margin-top:140px;
    }
    .webinar_video--descriptions p{
      text-align: center;
      font-size: 12px;
      margin: 0 auto;
      width: 40%;
      word-wrap: break-word;
    }
    .webinar_video {
      display: flex; 
      flex-direction: column;
      margin: 10px;
      gap: 40px;
    }
    .webinar_video--title p{
      width: 54%;
      margin-left: 92px;

    }
    .webinar_video video{
      width:65%;
    }
    .webinar_video--contents{
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
      width:90%;
    }
    .webinar_video--date{
      margin-left: -260px;
    }
    .webinar_video--text{
      margin-left: -13px;
    }
    .webinar_video--text p {
      font-family: 'Open Sans', sans-serif;
      width: 75%;
      margin-left: 77px;
  }
    .webinar_technical-content{
      height: 500px;
      margin-bottom: 250px;
    }
   
    .webinar_video--text a{
      margin-left: 79px;
    }
    .webinar_technical-content--para {
      font-size: 12px; 
      text-align: center;
      width:330px;
    }
    .video-tag-filters-wrapper{
      margin-top: 82px;
    }
    .webinar_video--playbutton,
    .webinar_video--pausebutton {
      position: relative; 
      left: -1px; 
      bottom: 118px; 
      margin: -23px 0; 
      transition: color 0.3s, transform 0.3s; 
    }
  
    .webinar_video--playbutton:hover,
    .webinar_video--pausebutton:hover {
      color: #FF7F00;
      transform: scale(1.02);
    }
   
  }
    

  /* Tablet responsive */
  @media screen and (min-width: 768px) and (max-width: 1024px) { 
    .webinar-wrapper{
      width:100%;
    }
    .webinar{
      margin-top: 200px;
  }
    .webinar_video--descriptions h1{
      text-align: center;
      width:900px;
      margin-top: 180px;
      font-size: 30px;
    }
    .webinar_video--descriptions p{
      text-align: center;
      font-size: 12px;
      margin: 0 auto;
      width: 60%;
      word-wrap: break-word;
    }
    .webinar_video {
      display: flex; 
      flex-direction: column;
      margin: 10px;
      gap: 40px;
    }
    .webinar_video video{
      width:100%;
      margin-right:92px;
      margin-top: 25px;
    }
    .webinar_video--contents{
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
      width:90%;
    }
    .webinar_video--date{
      margin-left: -443px;
      margin-top: 18px;
    }
    .webinar_video--text{
      margin-left: 68px;
    }
    .webinar_video--text p{
      margin-bottom: 20px;
      font-family: 'Open Sans', sans-serif;
      width: 102%;
      margin-left:2px;
  
    }
    .webinar_video--text a {
      margin-left: 4px;
    }
    .webinar_technical-content{
      height: 500px;
      margin-bottom: 250px;
    }
    .webinar_technical-content h1{
      margin-top: 85px;
      font-size: 30px;
    }
    .webinar_technical-content--para {
      font-size: 12px; 
      text-align: center;
      width:538px;
    }
    .video-tag-filters-wrapper{
      margin-top: 140px;
    }
    .webinar_video--playbutton,
    .webinar_video--pausebutton {
      position: relative; 
      left: -40px; 
      bottom: 118px; 
      margin: -23px 0; 
      transition: color 0.3s, transform 0.3s; 
    }
  
    .webinar_video--playbutton:hover,
    .webinar_video--pausebutton:hover {
      color: #FF7F00;
      transform: scale(1.02);
    }
    
    
   }