.industries-retail-wrapper{
  width: 100vw;
}

.industriesretail-container{
  padding-left: 3%;
}

.retail-img-sec img {
    margin-top: -100px;
    position: relative;
    width: 100%;
    object-fit: cover; 
    inset: 0;
    filter: brightness(55%);
    z-index: -1;
}
.retail-heading h1{
      position: absolute;
      top: 350px;
      left: 25%;
      font-size: 45px;
      font-family: 'Open Sans', 'Regular';
      text-align: center;
}
.second-retail-page
{
    width:1350px;
    height:400px;
    display:flex;
    background-color: black;
    gap:100px;
    justify-content: center;
    padding-top:100px;
    margin-left:210px;
}
.fourth-retail-page
{
    width:1350px;
    height:450px;
    margin-left:60px;
    margin-top: 100px;
}
.fifth-retail-page,.sixth-retail-page,.seventh-retail-page
{
    display:flex;
    width:1350px;
    height:350px;
    justify-content: center;
    gap:120px;
    margin-left:40px;
    
}
.seventh-retail-page{
  margin-top: 70px;
}

.shop-paragraph p
{
color:white;
font-size: 16px;
font-family: 'Open Sans', 'Regular';
text-align: left;
width: 60%;
margin-top: -20px;
line-height: 30px;
}
.paradigm-shift
{
    width:1050px;
    height:320px;
    background-color: #333333;;
    margin-top: 100px;
    margin-left: 200px;
    border-radius: 15px;
}
.retail-task {
    color:white;
    text-align: center;
    font-size: 16px;
    font-family: 'Open Sans', 'Regular';
    line-height: 30px;
}
.retail-paradigm 
{
    color:#FE780C;
    text-align: center;
    font-weight: bold;
    padding-top:50px;
    font-size: 25px;
    font-family: 'Open Sans', 'Regular';
}

.paradigm-shift p
{
    color:white;
    text-align: center;
    font-size: 16px;
    font-family: 'Open Sans', 'Regular';
   
}




.retail-work{
    text-align: center;
    font-weight: bold;
    font-size: 35px;
    font-family: 'Open Sans', 'Regular';
    font-weight: bold;
    padding-bottom: 30px;
} 

.bulletins-whole-container
{
   padding-left: 100px;
}



.ep-cont
{
    display: flex;
    justify-content: center;
    padding-top: 40px;
    gap:20px;
}


.ep-cont p{
 
  font-size: 16px;
  font-family: 'Open Sans', 'Regular';
  text-align: left;
  width: 30%;
  line-height: 30px;
}



.ci-cont
{
    display: flex;
    justify-content: center;
    gap:20px;
    
}
.ci-cont p{
  font-size: 16px;
  font-family: 'Open Sans', 'Regular';
  text-align: left;
  width: 30%;
  line-height: 30px;
}

.retail-approach{
    font-weight:bold;
    font-family: 'Open Sans', 'Regular';
    font-size: 25px;
}

.integration-paragraph p{
  font-size: 16px;
  font-family: 'Open Sans', 'Regular';
  text-align: left;
  line-height: 30px;

}
.create,.integrate
{
    width:350px;
    text-align: left;
}
.transparency,.provide
{
    width:350px;
    text-align: left;
}
.elipse{
    position: absolute;
    z-index: 1;
    top:1570px;
    left:150px;
    transform: translate(-50%, -50%);
    animation: rotate 3.5s linear infinite; /* Change the animation duration and timing function as per your preference */
  }

  .pyramid{
    position: absolute;
    z-index: 1;
    top:1150px;
    right:152px;
    transform: translate(-50%, -50%);
    animation: rotate 3.5s linear infinite; /* Change the animation duration and timing function as per your preference */
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }

.dotR{
  position: absolute;
  top: 275%; 
  right: 6%; 
  transform: translate(-50%, -50%); 
  z-index: 1; 
  animation: blink 1.5s infinite; 
}

.integral-approach
{
    padding-top:30px;
    font-size: 25px;
    font-family: 'Open Sans', 'Regular';
    font-weight: bold;
}


.fmgo
{
img{width:100%}
}

.retail-str{
    text-align: left;
    font-weight: bold;
    font-family: 'Open Sans', 'Regular';
    font-size: 25px;

}

.knock-paragraph p{
  font-size: 16px;
  font-family: 'Open Sans', 'Regular';
  text-align: left;
  line-height: 30px;
}
.smile-paragraph ,.smile-img
{
  padding-top:50px;
}

.smile-paragraph p{
  font-size: 16px;
  font-family: 'Open Sans', 'Regular';
  text-align: left;
  line-height: 30px;
}
.knock-paragraph
{
  padding-bottom: 30px;

}
.bullet1 {
  display: inline-block;
  width: 10px; /* Adjust the width of the bullet */
  height: 10px; /* Adjust the height of the bullet */
  background-color: orangered; /* Adjust the color of the bullet */
  border-radius: 50%; /* To make the bullet round */
  margin-top:10px;

}



.bullet2 {
  display: inline-block;
  width: 10px; /* Adjust the width of the bullet */
  height: 10px; /* Adjust the height of the bullet */
  background-color: orangered; /* Adjust the color of the bullet */
  border-radius: 50%; /* To make the bullet round */
  margin-top:10px;
}


.bullet3{
  display: inline-block;
  width: 10px; /* Adjust the width of the bullet */
  height: 10px; /* Adjust the height of the bullet */
  background-color: orangered; /* Adjust the color of the bullet */
  border-radius: 50%; /* To make the bullet round */
  margin-top:10px;

}


.bullet4 {
  display: inline-block;
  width: 10px; /* Adjust the width of the bullet */
  height: 10px; /* Adjust the height of the bullet */
  background-color: orangered; /* Adjust the color of the bullet */
  border-radius: 50%; /* To make the bullet round */
  margin-top:10px;
}


@media (max-width: 768px) {
  .industriesretail-container {
    padding-left: 0;
  }
  .retail-img-sec img {
    margin-top:70px;
    filter: brightness(100%);
  }
  .retail-heading h1 {
    margin-top: -220px;
    font-size: 22px;
    margin-left: -50px;
   width: 80%;
  }
  .second-retail-page {
    width: 100%;
    height: auto;
    flex-direction: column;
    gap: 20px;
    padding-top: 50px;
    margin-left: 0;
  }
   .second-retail-page img{
    width: 80%;
    margin-left: 30px;
   }
   .shop-paragraph p {
     font-size: 11px;
     margin-left: 20px;
    /* margin-right: 2000px; */
    width: 90%;
    margin-top: 20px;
   }
  .fourth-retail-page {
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-top: 50px;
  }
  .fifth-retail-page,
  .sixth-retail-page,
  .seventh-retail-page {
    width: 100%;
    height: auto;
    flex-direction: column;
    gap: 20px;
    margin-left: 0;
  }
  .seventh-retail-page {
    margin-top: 50px;
  }
  
  .paradigm-shift {
    width: 90%;
    height: auto;
    margin-top: 50px;
    margin-left: 20px;
  }
  .retail-task {
    font-size: 12px;
  }
  .retail-paradigm {
    font-size: 17px;
    padding-top: 30px;
    
  }
  .paradigm-shift p {
    font-size: 12px;
  }

  .retail-work {
    font-size: 22px;
    padding-bottom: 20px;
  }
  .bulletins-whole-container {
    padding-left: 0;
  }
  .ep-cont,
  .ci-cont {
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
    margin-left: 40px;
  }
  .ep-cont p,
  .ci-cont p {
    width: 100%;
    font-size: 12px;
    margin-left: 30px;
    margin-top: -30px;
  }
  .retail-approach {
    font-size: 20px;
  }
  .integration-paragraph p {
  display: none;
  }
  .create,
  .integrate,
  .transparency,
  .provide {
    width: 100%;
  }
  .elipse {
    top: 900px;
    left: 80%;
    transform: translate(-50%, -50%);
  }
  .pyramid {
    top: 1350px;
    right: 80%;
    transform: translate(50%, -50%);
  }
  .dotR {
    top: 680px;
    right: 20%;
    transform: translate(50%, -50%);
  }
  .integral-approach {
    padding-top: 20px;
    font-size: 20px;
  }
  .fmgo img {
    width: 100%;
  }
  .retail-str {
    font-size: 20px;
    margin-left: 20px;
    color: #FE780C;
    margin-top: -10px;
  
  }
  .knock-img img{
    width: 80%;
    margin-left: 30px;
    margin-top: 30px;
  }
  .knock-paragraph p {
   
  display: none;
  }
  .hide-on-laptop6{
    font-size: 12px;
    margin-left: 20px;
    font-family: 'Open Sans', 'Regular';
    line-height: 20px;
    margin-top: -30px;
  }
   
  .smile-paragraph,
  .smile-img {
    padding-top: 30px;
  }
  .retail-approach{
    font-size: 19px;
    margin-left: 25px;
    color: #FE780C;
    margin-top: 220px;

  }
  .smile-paragraph p {
    display: none;
  }
  .knock-paragraph {
    padding-bottom: 10px;
  }
  .smile-img img{
    width: 80%;
    margin-left: 30px;
    margin-top: -650px;
  }
  .integration-img img{
    width: 80%;
    margin-left: 30px;
    margin-top: -80px;
  }
  .integral-approach{
    font-size: 22px;
    margin-left: 20px;
    color: #FE780C;
    margin-top: -30px;
  }
  .bullet1,
  .bullet2,
  .bullet3,
  .bullet4 {
    margin-top: 5px;
  }
  .fmgo{
    margin-top: 30px;
  }
}
@media only screen and (min-width: 1024px) {
  .hide-on-laptop6 {
    display: none; /* Hide the content on screens with a width of 1024 pixels or larger (laptops and desktops) */
  }
}
@media (min-width: 768px) and (max-width: 1366px)
{
  .retail-heading h1{
    margin-left:-100px;
    margin-top:-80px;
  }
  .second-retail-page
  {
    margin-left:120px;
  }
  .fourth-retail-page{
    margin-left:-80px;

  }
  .paradigm-shift{
margin-left: 100px;
 }

  .fifth-retail-page{
    margin-left: -40px;
  }
  .sixth-retail-page{
    margin-left: -10px;
    gap:60px;
  }
  .seventh-retail-page{
    margin-left: 10px;
  }
  /* .paradigm-shift{
   background-color: #FE780C;
   
  } */
  
}


