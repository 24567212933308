
.serviceContent-wrapper{
    width: 100vw;
}


/* Head section */
.serviceContent-container {
    display: flex;
    
    justify-content: center;
    flex-direction: column;
}


.serviceContent-headtext {
    font-size: 55px;
    margin-bottom: 0;
    margin-left: 5.1rem;
    margin-top: -30rem;
    display: flex;
    justify-content:center ;
    align-items: center;
    text-align: center;
    font-family: 'Open Sans', 'Regular';
    color: white;
    font-weight: bold;
    width: 90%;
 }



.serviceContent-img{
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    position: relative;
    width: 100vw;
    z-index: -1;
    background-color: black;
    /* filter: grayscale(100%); */
    filter: brightness(70%);
    top: 0;
}




.serviceContent-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

 
}

.serviceContent-content-wrap{
    margin-top: 150px;
   
}



.serviceContent-left img {
    display: block;
    position: inherit;
    padding-top: 2%;
    padding-bottom: 80px;
    margin-left: 180px;
    object-fit: cover;
     
}


.serviceContent-right {
    display: block;
    position: absolute;
    top: 9rem;
    left: 45rem;
}


.serviceContent-right h1 {
     color: #fff;
     display: flex;
     color: white;
    text-align: center;
    font-size: 30px;
    font-family: 'Open Sans', 'Regular';
    
}



.rightContent-text {
    display: inline-flex;
    font-family: 'Open Sans', 'Regular';
    font-size: 16px;
    color: white;
    text-align: left;
    margin-top: 15px;
    line-height: 30px;
}

.serviceContent-dlux-onestop-section{
    margin-bottom: 70px;
}
.serviceContent-dlux-onestop-section h1{
    color: #fff;
    display: flex;
    justify-content: center;
    text-align: right;
    font-size: 45px;
    font-family: 'Open Sans', 'Regular';
    font-weight: bold;
    padding-bottom: 30px;
  
}
.serviceContent-dlux-onestop-section p {
    color: #fff;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-family: 'Open Sans', 'Regular';
    text-align: center;
    line-height: 30px;
}

.serviceContent-OurServices{
    display: flex;
    flex-direction: column;
    justify-content: left;
    color: #fff;
    background-image: linear-gradient(to right, #333333,#0d0d0d);
   margin-top: 10px;
   padding-bottom: 60px;
}
.serviceContent-OurServices h1{
    text-align: left;
    padding-left: 180px;
    font-size: 45px;
    margin-top: 40px;
    color: #fff;
    font-weight: bold;
    font-family: 'Open Sans', 'Regular';
}
.serviceContent-OurServices-contentsection{
    display: flex;
    justify-content: center;
    gap:150px;
    margin-left: -110px;
}
.serviceContent-OurServices-left {
    margin-top: 50px;   
    color: #fff;
}
.serviceContent-OurServices-left h3{
    font-size:25px;
    color: #fff;
    font-family: 'Open Sans', 'Regular';

}
.serviceContent-OurServices-left p{
    font-size: 16px;
   text-align: left;
    font-family: 'Open Sans', 'Regular';
    line-height: 30px;

}

.serviceContent-OurServices-right {
    margin-top: 50px;
   color: #fff;
}
.serviceContent-OurServices-right h3{
    font-size: 25px;
    color: #fff;
    font-family: 'Open Sans', 'Regular';
   
}
.serviceContent-OurServices-right p{
    font-size: 16px;
    text-align: left;
     font-family: 'Open Sans', 'Regular';
     line-height: 30px;
}

/* partnership */

.serviceContent-OurServices-Partnership h1{
    color: #fff;
    font-size: 45px;
    text-align: center;
    margin-top: 70px;
    font-family: 'Open Sans', 'Regular';
    font-weight: bold;
}
.ServiceContent-OurServices-Partnership-content{
 display: flex;
justify-content: center;
align-items: center;
margin-top: 80px;
gap: 240px;
}
.serviceContent-OurServices-Partnership-content1 h2{
    color: #fff;
    font-size: 25px;
    font-family: 'Open Sans', 'Regular';
    font-weight: bold;
}
.serviceContent-OurServices-Partnership-content1 p{
    color: #fff;
    font-size: 16px;
    text-align: left;
    font-family: 'Open Sans', 'Regular';
    line-height: 30px;

}
.serviceContent-OurServices-Partnership-content2{
    margin-top: -26px;
    
}
.serviceContent-OurServices-Partnership-content2 h2{
    color: #fff;
    font-size: 25px;
    font-family: 'Open Sans', 'Regular';
    font-weight: bold;
}
.serviceContent-OurServices-Partnership-content2 p{
    color: #fff;
    font-size: 16px;
    text-align: left;
    font-family: 'Open Sans', 'Regular';
    line-height: 30px;
}
   /* dlux */
.serviceContent-OurServices-dlux h1{
    text-align: left;
    margin-left: 180px;
    color: #fff;
    margin-top: 100px;
    font-size: 45px;
    font-family: 'Open Sans', 'Regular';
    font-weight: bold;
    padding-bottom: 40px;
}
.serviceContent-OurServices-dlux-content-gif-wrap{
    display: flex;
    gap: 100px;
}

.serviceContent-OurServices-dlux-content{
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.serviceContent-OurServices-dlux-img-wrap{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 30px;
    margin-left: 180px;
}
.serviceContent-OurServices-dlux-content1 h3{
      font-size: 25px;
      color: #fff;
      font-family: 'Open Sans', 'Regular';
      font-weight: bold;
}
.serviceContent-OurServices-dlux-content1 p{
    font-size: 16px;
    color: #fff;
    text-align: left;
    font-family: 'Open Sans', 'Regular';
    line-height: 30px;
}
.serviceContent-OurServices-dlux-content1  img{
    margin-top: 40px;
}
.serviceContent-OurServices-dlux-Dgif{
    margin-top: 80px;
}
.crave1 {
    position: relative;
    overflow: hidden;
}
.crave1 img {
    width: 100%;
    display: block;
    object-fit: cover;
    margin-top: 80px;
   
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg); 
    }
    to {
      transform: rotate(360deg); 
  }
}

.dam-blink1{
    position: absolute;
    top: 234%; 
    right: 6%; 
    animation: blink 1.5s infinite;
}

.dam-blink2{
    position: absolute;
    top: 280%; 
    right: 28%; 
    animation: blink 1.5s infinite;
}

.dam-blink3{
    position: absolute;
    top: 365%; 
    left: 10%; 
    animation: blink 1.5s infinite;
}

.dam-diamond{
    position: absolute;
    top: 218%; 
    left: 12%; 
    transform: translate(-50%, -50%); 
    z-index: 1; 
    animation: rotate 4s linear infinite; 
}

.dam-globe{
    position: absolute;
    top: 355%; 
    right: 10%; 
    transform: translate(-50%, -50%); 
    z-index: 1; 
    animation: rotate 4s linear infinite; 
}

.cmad-footer{
  margin-right: 17px;
}


/* For screens smaller than 768px */
@media (max-width: 768px) {
  .serviceContent-container img{
          margin-top: 100px;
          filter: brightness(100%);
  }
  .serviceContent-container h1{
    font-size: 22px;
    margin-top: -170px;
    margin-left: 25px;
  }
    .serviceContent-headtext {
      font-size: 40px;
      margin-top: -20rem;
    }
  
    .serviceContent-left img {
      margin-left: 20px;
      margin-top: -70px;
      width: 90%;
    }
  
    .serviceContent-right {
      left: 0;
    }
    .serviceContent-right h1{
      font-size: 22px;
      margin-top: 350px;
      margin-left: 20px;
    }
    .rightContent-text {
    display: none;

    }
    .hide-on-laptop61{
      font-size: 12px;
      width: 90%;
      text-align: left;
      line-height: 20px;
      margin-left: 20px;
      font-size: 'Open Sans', 'Regular';
    }

     .serviceContent-dlux-onestop-section{
      margin-top: 450px;
     }
     .serviceContent-dlux-onestop-section h1{
      font-size: 22px;
      text-align: center;
      
     }
     .serviceContent-dlux-onestop-section p{
      display: none;
     }
    .hide-on-laptop62{
      font-size: 12px;
      line-height: 22px;
      text-align: left;
      margin-left: 20px;
      font-family: 'Open Sans', 'Regular';
      margin-top: -90px;
    }
    .serviceContent-OurServices-contentsection {
      gap: 50px;
      margin-left: 0;
      flex-direction: column;
    }
    .serviceContent-OurServices h1{
      font-size: 30px;
      margin-left: -60px;
    }
    .serviceContent-OurServices-left {
      margin-left: 20px;
    }
    .serviceContent-OurServices-left h3{
      font-size: 22px;
      width: 100%;
    }
    .serviceContent-OurServices-left p{
      font-size: 11.5px;
      text-align: left;
      width: 100%;
    }
    .serviceContent-OurServices-right{
      margin-left: 20px;
      margin-top: -20px;
    }
    .serviceContent-OurServices-right h3{
      font-size: 22px;
    }
    .serviceContent-OurServices-right p{
      font-size: 12px;
      text-align: left;
      width: 100%;
    }
    .ServiceContent-OurServices-Partnership-content{
         margin-top: -10px;
         flex-direction: column;
    }
    .serviceContent-OurServices-Partnership h1{
    font-size: 22px;
    }
    .serviceContent-OurServices-Partnership-content1{
      margin-top: 30px;
      margin-left: 20px;
    }
    .serviceContent-OurServices-Partnership-content1 img{
      width: 90%;
    
    }
    .serviceContent-OurServices-Partnership-content1 h2{
      font-size: 22px;
    }
    .serviceContent-OurServices-Partnership-content1 p{
      font-size: 11px;
      width: 100%;
    }
    .serviceContent-OurServices-Partnership-content2{
      margin-left: 20px;
      margin-top: -190px;
    }
    .serviceContent-OurServices-Partnership-content2 img{
       width: 90%;
    }
    .serviceContent-OurServices-Partnership-content2  h2{
      font-size: 22px;
    }
     .serviceContent-OurServices-Partnership-content2  p{
      font-size: 11px;
      width: 100%;
     }
     .serviceContent-OurServices-dlux h1{
      font-size: 22px;
      margin-left: 90px;
     }
     .serviceContent-OurServices-dlux-img{
       width: 20%;
     }
     .serviceContent-OurServices-dlux-text h3{
      font-size: 22px;
     }
     .serviceContent-OurServices-dlux-text p{
      font-size: 12px;
     }
  
     .serviceContent-OurServices-dlux-Dgif {
    display: none;
    
    }
  
    .serviceContent-OurServices-dlux-img-wrap {
      gap: 20px;
      margin-left: 0;
    }
  
    .serviceContent-OurServices-dlux-Dgif img {
      margin-top: 50px;
    }
  
    .crave1 img {
   margin-top: 10px;
    }
  
    .dam-blink1,
    .dam-blink2,
    .dam-blink3,
    .dam-diamond,
    .dam-globe {
      display: none;
    }
  }
  
  /* For screens between 768px and 1024px */
  /* @media (min-width: 768px) and (max-width: 1024px) {
    .serviceContent-headtext {
      font-size: 45px;
      margin-top: -25rem;
    }
  
    .serviceContent-left img {
      margin-left: 0;
    }
  
    .serviceContent-right {
      left: 0;
    }
    .serviceContent-right p{
      display: none;
    }
  
    .serviceContent-OurServices-contentsection {
      gap: 100px;
      margin-left: 0;
    }
  
    .serviceContent-OurServices-dlux-content-gif-wrap {
      gap: 100px;
    }
  
    .serviceContent-OurServices-dlux-img-wrap {
      gap: 50px;
      margin-left: 0;
    }
  
    .serviceContent-OurServices-dlux-Dgif img {
      margin-top: 50px;
    }
  
    .crave1 img {
      margin-top: 50px;
    }
  
    .dam-blink1,
    .dam-blink2,
    .dam-blink3,
    .dam-diamond,
    .dam-globe {
      display: none;
    }
  } */
  
  /* For screens larger than 1024px */
  /* @media (min-width: 1024px) {
    .serviceContent-headtext {
      font-size: 55px;
      margin-top: -30rem;
    }
  
    .serviceContent-left img {
      margin-left: 180px;
    }
  
    .serviceContent-right {
      left: 45rem;
    }
    .serviceContent-right p{
      display: none;
    }
  
    .serviceContent-OurServices-contentsection {
      gap: 150px;
      margin-left: -110px;
    }
  
    .serviceContent-OurServices-dlux-content-gif-wrap {
      gap: 100px;
    }
  
    .serviceContent-OurServices-dlux-img-wrap {
      gap: 30px;
      margin-left: 180px;
    }
  
    .serviceContent-OurServices-dlux-Dgif img {
      margin-top: 100px;
    }
  
    crave1 img {
      margin-top: 80px;
    }

    .dam-blink1,
    .dam-blink2,
    .dam-blink3,
    .dam-diamond,
    .dam-globe {
      display: block;
    }
  } */
  @media only screen and (min-width: 1024px) {
    .hide-on-laptop61 {
      display: none; /* Hide the content on screens with a width of 1024 pixels or larger (laptops and desktops) */
    }
    .hide-on-laptop62{
      display: none;
    }
  }
  @media (min-width: 768px) and (max-width: 1366px)
  {
    .serviceContent-dlux-onestop-section
    {
      margin-top:100px;
    }
    .ServiceContent-OurServices-Partnership-content
    {
      gap:100px;
    }
    .serviceContent-OurServices-contentsection
    {
    margin-left:70px;
    }
    .serviceContent-content-wrap
    {
      margin-right:600px;
    }
    .serviceContent-OurServices-dlux-content1 p br{
      display: none;
    }
    .serviceContent-OurServices-dlux-Dgif
    {
      margin-right:70px;
    }
    .dam-blink3
    {
      left:100px;
      top:2200px;
    }
    .serviceContent-left
    {
     margin-right:600px;
    }
    .rightContent-text p
    {
       width:1400px;
    }
    .Training-analysis h1
    {
      width:500px;
      
    }
  .rightContent-text br{
          display:none;
    }
    .rightContent-text
    {
      width:600px;
      text-align: justify;
    }
    .dam-globe{
      margin-right:-100px;
    }
  }
  @media (min-width: 770px) and (max-width: 900px)
  {
  .serviceContent-container {
    margin-top:100px;
  }
  .serviceContent-headtext
  {
    margin-top:-350px;
  }
  .rightContent-text
  {
    margin-left:-580px;
  }
  .serviceContent-content-wrap
  {
    margin-left:-10px;
    margin-bottom:400px;
  }
  .serviceContent-right h1{
    margin-left:-520px;
    margin-top:480px;
  }
  .serviceContent-dlux-onestop-section p br
  {
   display:none;
  }
  .serviceContent-dlux-onestop-section p
  {
    width:600px;
    margin-left:80px;
  }
  .serviceContent-OurServices h1
  {
    margin-left:80px;
  }
  .serviceContent-OurServices-contentsection
  {
    display: flex;
    flex-direction:column;
    gap:10px;
    margin-left: 200px;
  }
  .ServiceContent-OurServices-Partnership-content
  {
    display:flex;
    flex-direction:column;
  }
  .serviceContent-OurServices-dlux-text p br 
  {
  display: none;
  }
  .dgif
  {
    display:none;
  }
  .dam-diamond{
    margin-top:-210px;
  }

}