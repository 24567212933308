
.serviceApplication-wrapper{
    width: 100vw;
}


/* Head section */
.serviceApplication-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Open Sans', 'Regular';
    
}

.serviceApplication-container {
    display: flex;
    flex-direction: column;

}

.serviceApplication-head-text{
    font-size: 55px;
    margin-left: 3rem;
    margin-bottom: 0;
    margin-top: -33rem;
    margin-left: 28rem;
    font-family: 'Open Sans', 'Regular';
    color: white;
    font-weight: bold;
    width: 49%;
}

.serviceApplication-img {
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    position: relative;
    height: 100%;
    width: 100vw;
    z-index: -1;
    background-color: black;
    /* filter: grayscale(100%); */
    filter: brightness(55%);
    top: 0;
    margin-top: -100px;
  
}

.service-head-Application {
    width: 100%;
    background: no-repeat center center fixed;
    background-size: cover;
    filter: grayscale(100%);
    background-color: black;
    z-index: -1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1.5);
}

.service-head-Application::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    z-index: -1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.serviceApplication-section{
    margin-top: -180px;
    margin-left: 70px;
}
.serviceApplication-content {
    position: relative;
    width: 100%;
    max-width: 85rem;
}


.serviceApplication-left img {
    display: block;
    position: inherit;
    padding-top: 10%;
    padding-bottom: 10%;
    width: 500px;
    margin-left: 0;
    height: 100%;
    object-fit: cover;
    margin-top: 10rem;
}

.leftApplication-text {
    display: inline-flex;
    font-family: 'Open Sans', 'Regular';
    position: relative;
    font-size: 16px;
    color: var(--p-color);
    text-align: left;
    line-height: 30px;
    width: 39%;
    bottom: 70px;
    left: 10px;
}

.serviceApplication-right {
    display: block;
    position: absolute;
    top: 7rem;
    right: 0;
    left: 42rem;
    width: 47%;
    height: 100%;
}

.serviceApplication-right h3{
    font-family: 'Open Sans', 'Regular';
    font-size: 30px;
}

.rightApplication-text {
    display: inline-flex;
    font-family: 'Open Sans', 'Regular';
    font-size: 16px;
    color: var(--p-color);
    text-align: left;
    line-height: 30px;
}
.StandApplication-div{
    display: flex;
    
}
.standApplication-section {
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    background: linear-gradient(to bottom right, rgb(46, 45, 45), rgb(0, 0, 0));
    margin-top: 5rem;

  
}

.standApplication-head{
    color: white;
    text-align: center;
    margin: 0 auto; /* This centers the element horizontally */
    font-size: 45px;
    font-family: 'Open Sans', 'Regular';
    margin-top: 20px;
    width: 50%;
}
.StandApplication-content{
    position: relative;
    padding-left: 170px;
    
    
}
.StandApplication-content img{
    display: block;
    position: inherit;
    margin-left: 0;
    object-fit: cover;
    margin-top: 5rem;
}
.StandApplication-content p{
   
    font-family: 'Open Sans', 'Regular';
    font-size: 16px;
    color: white;
    text-align: left;
    line-height: 30px;
    left: 10px;
    margin-top: 10px;
}
.StandApplication-content h1{
    margin-left: 3px;
    font-size: 20px;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    font-family: 'Open Sans', 'Regular';
    text-align: left;
}
.StandApplication-content11{
    position: relative;
    margin-left: 200px;
    
}
.StandApplication-content11 img{
    display: block;
    position: inherit;
    margin-left: 0;
    object-fit: cover;
    margin-top: 5rem;
    margin-left: 100px;
}
.StandApplication-content11 p{
   
    font-family: 'Open Sans', 'Regular';
    font-size: 16px;
    line-height: 30px;
    text-align: left;
    color: white;
    left: 10px;
    margin-top: 10px;
    margin-left: 100px;
}
.StandApplication-content11 h1{
    margin-left: 3px;
    font-size: 20px;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    font-family: 'Open Sans', 'Regular';
    margin-left: 100px;
}
.StandApplication-div1{
    display: flex;
}

.StandApplication-content1{
    position: relative;
    padding-left: 170px;
}
.StandApplication-content1 img{
    display: block;
    position: inherit;
    margin-left: 0;
    object-fit: cover;
    margin-top: 5rem;
}
.StandApplication-content1 p{
   
    font-family: 'Open Sans', 'Regular';
    font-size: 16px;
    line-height: 30px;
    color: white;
    text-align: left;
    margin-top: 10px;
}
.StandApplication-content1 h1{
    font-size: 20px;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    font-family: 'Open Sans', 'Regular';
}
.StandApplication-content12{
    position: relative;
    margin-left: 160px;
}
.StandApplication-content12  img{
    display: block;
    position: inherit;
    margin-left: 0;
    object-fit: cover;
    margin-top: 5rem;
    margin-left: 100px;
}
.StandApplication-content12 p{
   
    font-family: 'Open Sans', 'Regular';
    font-size: 16px;
    line-height: 30px;
    color: white;
    text-align: left;
    left: 10px;
    margin-top: 10px;
    margin-left: 100px;
}
.StandApplication-content12 h1{
    margin-left: 3px;
    font-size: 20px;
    color: white;
    font-weight: bold;
    margin-top: 20px;
    font-family: 'Open Sans', 'Regular';
    margin-left: 100px;
}



/* application */
.ApplicationWorks-section{
    margin-top: 40px;
    display: flex;  
    position: relative;
    gap:20px
}

.Applicationprocess-align{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    position: relative;
    gap:20px;
}




.Applicationprocess-align-text{
    padding-left: 20px;
    
}

.Works h1{
    color: white;
    text-align: left;
    font-size: 45px;
    font-family: 'Open Sans', 'Regular';
    font-weight: bold;
    margin-top: 20px;
}

.Works p{
    color: #fff;
    font-family: 'Open Sans', 'Regular';
    font-size: 16px;
    color: white;
     text-align:left ;
     line-height: 30px;
}

.Works h5{
    color: #ffffff;
    font-size: 20px;
    font-weight:bold;
    font-family: 'Open Sans', 'Regular';
}

.Works{

}

.gifA{
   
    margin-top: 70px;
  
}
.corner11 {
    position: relative;
    overflow: hidden;
}
.corner11 img {
    margin-top: 70px;
    width: 100%;
}

@keyframes rotate {
    from {
      transform: rotate(0deg); 
    }
    to {
      transform: rotate(360deg); 
  }
}

.applications-stuff1 img{
    position: absolute;
    top: -1435px; 
    transform: translate(-50%, -50%); 
    z-index: 1; 
    animation: rotate 4s linear infinite; 
  }

  .applications-stuff2 img{
    position: absolute;
    top: -1655px; 
    left: 20px;
    transform: translate(-50%, -50%); 
    z-index: 1; 
    animation: rotate 4s linear infinite; 
  }

  .serviceapplication-footer{
    margin-right: 17px;
  }

  /* Media query for screens with a maximum width of 768px */
@media (max-width: 768px) {
    .serviceApplication-container {
        margin-top: 100px;
        
    }
    
    .serviceApplication-head-text {
        font-size: 14px;
        margin-left: 0px;
        margin: 0 auto;
        margin-top: -10rem;
        width: 46%;
    }

    .serviceApplication-container img{
        width: 100%;
        margin-top: -50px;
        filter: brightness(100%);
    }

    .serviceApplication-section {
        margin-top: -100px;
        margin-left: 20px;
    }
    /* .rightApplication-text br:not(:first-child) + br,
    .rightApplication-text br:not(:first-child) {
      display: none;
    } */

    .serviceApplication-left img {
        width: 300px;
        margin-top: 5rem;
        margin-left: 20px;
    }

    .leftApplication-text {
        width: 90%;
        bottom: 0;
        left: 0;
        margin-top: 1rem;
        margin-left: 20px;
        font-size: 12px;
        margin-right: 20px;

    }
    .serviceApplication-right h3{
        font-size: 23px;
        margin-left: 20px;
    }

    .serviceApplication-right {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        margin-top: 2rem;
        font-size: 12px;
    }
    .serviceApplication-right p{
        font-size: 14px;
        margin-left: 20px;
        margin-right: 20px;
        width: 90%;
    }
    
    .standApplication-section {
        width: 100%;
    }
    .standApplication-head{
        font-size: 19px;
        margin-top: -10px;
        width: 56%;
    }

    .StandApplication-div {
        flex-direction: column;
    }

    .StandApplication-content {
        padding-left: 0;
        margin-left: 0;
    }

    .StandApplication-content img {
        margin-top: 2rem;
        width: 80%;
        margin-left: 40px;
    }
   
.hide-mobile{
    display: none;
}
    .StandApplication-content p {
        margin-top: 1rem;
        font-size: 12px;
        width: 90%;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
    }

    .StandApplication-content h1 {
        margin-top: 2rem;
        font-size: 20px;
        margin-left: 100px;
    }

    .StandApplication-content11 {
        margin-left: 0;
    }

    .StandApplication-content12 {
        margin-left: 0;
    }

    .StandApplication-content11 {
        padding-left: 0;
        margin-left: 0;
    }

    .StandApplication-content11 img {
        margin-top: 2rem;
        width: 80%;
        margin-left: 40px;
    }

    .StandApplication-content11 p {
        margin-top: 1rem;
        font-size: 12px;
        width: 90%;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
    }

    .StandApplication-content11 h1 {
        margin-top: 2rem;
        font-size: 20px;
        margin-left:0px;
        text-align: center;
    }
    .StandApplication-div1{
        flex-direction: column;
    }
    .StandApplication-content1{
        padding-left: 0;
        margin-left: 0;
    }
    .StandApplication-content1 img{
        margin-top: 2rem;
        width: 80%;
        margin-left: 40px;
    }
    .StandApplication-content1 p{
        margin-top: 1rem;
        font-size: 12px;
        width: 90%;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
    }
    .StandApplication-content1 h1{
        margin-top: 2rem;
        font-size: 20px;
        margin-left: 0px;
        text-align: center;
    }

    .StandApplication-content12 img {
        margin-top: 2rem;
        width: 80%;
        margin-left: 40px;
    }

    .StandApplication-content12 p {
        margin-top: 1rem;
        font-size: 12px;
        width: 90%;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
    }

    .StandApplication-content12 h1 {
        margin-top: 2rem;
        font-size: 20px;
        margin-left: 30px;
        width: 90%;
        text-align: center;
    }

    .ApplicationWorks-section {
        flex-direction: column;
        margin-left: 30px;
        margin-right: 30px;
        width: 100%;
    }

    .Applicationprocess-align {
        flex-direction: column;
        margin-top: 2rem;
    }
    .Applicationprocess-align-img{
        width: 20%;
        margin-left: -250px;
        margin-top: 50px;
        margin-bottom: -40px;
    }

    .Applicationprocess-align-text {
        padding-left: 0;
    }

    .Works h1 {
        font-size: 22px;
        margin-top: 2rem;
        margin-left: 40px;
    }

    .Works p {
        margin-top: 1rem;
        font-size: 12px;
        margin-left: 20px;
    }

    .Works h5 {
        font-size: 17px;
        margin-top: 2rem;
        margin-left: 20px;
    }

    .gifA {
       display: none;
    }

    .corner11 img {
     margin-top: 10px;
    }

    .applications-stuff1 img {
        display: none;
    }

    .applications-stuff2 img {
        top: -1600px;
        left: 20%;
        width: 10%;
    }
}
@media only screen and (min-width: 1024px) {
    .hide-on-laptop80 {
      display: none; /* Hide the content on screens with a width of 1024 pixels or larger (laptops and desktops) */
    }
  }
  @media (min-width: 768px) and (max-width: 1366px)
  {
    .serviceApplication-wrapper{
        overflow: hidden;
    }
    .serviceApplication-left
    {
      margin-left: 20px;
    }
    .serviceApplication-right
    {
        margin-left: -30px;
    }
    .serviceApplication-head-text
    {
        margin-left: 320px;
        margin-top:-420px;
    }
    .Applicationprocess-align-text p  br{
        display: none;
    }
    .Applicationprocess-align-text p
    {
        width:450px;
    }
    .applications-stuff1
    {
        margin-left:-100px;
    }
    .StandApplication-content11 img,.StandApplication-content11 h1, .StandApplication-content11 p{
        margin-left:0px;
    }
    .StandApplication-content12 img,.StandApplication-content12 h1, .StandApplication-content12 p{
        margin-left:0px;
    }
  }
@media (min-width: 790px) and (max-width: 1100px){
    .serviceApplication-content {
        display: flex;
        flex-direction: column;
    }
    .rightApplication-text
    {
        margin-left:-700px;
        margin-top:1020px;
    }
    .serviceApplication-content
    {
        margin-bottom:400px;
    }
    .StandApplication-div
    {
        display:flex;
        flex-direction:column;
        gap:-10px;
    }
    .StandApplication-div1
    {
        display:flex;
        flex-direction:column;
        gap:-10px;
    }
    .StandApplication-content12
    {
        margin-left:-50px;
    }
    .gifA
    {
      display:none;
    }
    .serviceApplication-right p br
    {
        display:none;
    }
    .serviceApplication-right p
    {
        width:1000px;   
        margin-left:-570px; 
    }
    .serviceApplication-right h3
    {
        margin-top:80px;   
        margin-left:-570px; 
    }
    .serviceApplication-left p br
    {
     display:none;
    }
    .serviceApplication-left p
    {
        width:500px;
    }
    .leftApplication-text
    {
        margin-top:80px;
    }
    .rightApplication-text
    {
        margin-top:-800px;
        margin-left:100px;
    }
    .serviceApplication-content
    {
        margin-bottom:700px;
    }
    .serviceApplication-right h3
    {
        margin-top:950px;
    }
    .standApplication-section
    {
        margin-top:200px;
    }
    .serviceApplication-container
    {
        margin-top:200px;
    }
    .service-head-Application h1 br
    {
        display:none; 
    }
    .service-head-Application h1 
    {
        margin-left:100px;
    }
    
}
