.cmp-image-left_section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #000;
  padding: clamp(10px, 5vw, 40px);
  gap: clamp(10px, 5vw, 20px);
  text-align: center;
}

.cmp-image-left_section img {
  width: clamp(100px, 25vw, 300px);
  height: auto;
  border-radius: clamp(5px, 1vw, 12px);
  box-shadow: 0px clamp(2px, 0.5vw, 6px) clamp(4px, 1vw, 12px) rgba(255, 255, 255, 0.1);
  flex: 0 0 auto;
  order: -1;
  max-width: 40%;
}

.cmp-image-left_description {
  flex: 1 1 500px;
  max-width: 60%;
  text-align: start;
  order: 1;
}

.cmp-image-left_description p {
  font-family: 'Open Sans', sans-serif;
  font-size: clamp(14px, 2vw, 18px);
  line-height: clamp(1.4, 2vw, 1.8);
  color: #fff;
  margin: 0;
  padding: clamp(10px, 2vw, 20px);

}

.cmp-image-left_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  padding: clamp(10px, 5vw, 40px);
  text-align: center;


  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    text-align: start;
    display: flex;
  }
}

.cmp-image-left_wrapper {

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
  }

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: clamp(40px, 5vw, 80px);
    gap: clamp(20px, 4vw, 40px);
    padding: clamp(20px, 3vw, 40px);
    max-width: 100%;
    box-sizing: border-box;


  }
}

.cmp-image-left_section img {
  width: clamp(100px, 25vw, 300px);
  height: auto;
  border-radius: clamp(5px, 1vw, 12px);
  margin-bottom: clamp(10px, 4vw, 20px);
  margin-top: clamp(30px, -4vw, 0px);
  box-shadow: 0px clamp(2px, 0.5vw, 6px) clamp(4px, 1vw, 12px) rgba(255, 255, 255, 0.1);


  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: clamp(10px, 5vw, 20px);
    margin-top: 101px;
  }

  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    margin-right: clamp(10px, 5vw, 20px);
    margin-top: -317px;
  }
}

.cmp-image-left_description {
  max-width: clamp(280px, 80%, 800px);
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: 0;
    flex: 1;
  }
}

.cmp-image-left_description p {
  font-family: 'Open Sans', sans-serif;
  font-size: clamp(13px, 2vw, 18px);
  line-height: clamp(1.4, 2vw, 1.8);
  color: #fff;
  margin: 0;
  text-align: center;
  padding: clamp(10px, 2vw, 20px);

  @media (min-width: 768px) {
    margin-bottom: 0;
    text-align: start;
    margin-top: 84px;

  }

  @media (min-width: 1024px) {
    margin-bottom: 0;
    margin-right: clamp(10px, 5vw, 20px);
    margin-top: -253px;

  }
}