

.partners-partnership-title{
    color: white;  
    text-align:center;
    font-weight: bold;
    padding-top:20px;
}

.partners-partnership-title h1{
  font-size: 45px;
  text-align: center;
  font-style: 'Open Sans', 'Regular';
  font-weight: bold;
  padding-bottom: 25px;
}


.partners-partnersheart-para p{
    color: white;
    font-size: 16px;
    text-align:center;
    font-family: 'Open Sans', 'Regular';
    line-height: 30px !important;
}
.partners-squaresOne-p
{
    display:flex;
    justify-content: center;
    gap:100px;
    margin-top: 60px;
}
.partners-square
{
    display: flex;
    background-color: white;
    width:240px;
    height:150px;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.partners-squaresTwo-p
{
    display:flex;
    justify-content: center;
    gap:100px;
    margin-top: 100px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.partners-loading {
    position: absolute;
    right: 90px;
    z-index: 1;
    top:1560px;
    transform: translate(-50%, -50%);
    animation: rotate 3.5s linear infinite; 
  }

  
.partners-circleRotate {
    animation: blink 1.5s infinite; 
    height:10px;
    position: absolute;
    left:250px;
    z-index: 100;
    top: 2860px;
}
@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }

  @media (max-width: 768px) {
   .partners-whole-p
    {
      overflow-x: hidden;
    }
    .partners-loading {
     display: none;
      
    }
    .partners-partnership-title h1 {
      font-size: 22px;
      margin-bottom: 0px;
    }
    .partners-partnership-title {
      padding-top: 10px;
      padding-bottom: 15px;
    }
    .partners-partnersheart-para p {
      font-size: 12px;
      line-height: 25px;
      text-align: center;
      margin-top: -25px;

    }
    .partners-lineOne-p1 p{
      display: none;
    }
    .hide-on-laptop73{
      font-size: 12px;
      font-family: 'Open sans', 'Regular';
      width: 95%;
      margin-left: 10px;
      text-align: center;
      line-height: 22px;
    }
    .partners-squaresOne-p, .partners-squaresTwo-p {
      flex-direction: column;
      gap: 20px;
      margin-top: 30px;
    }
    .partners-square {
      width: 80%;
      /* height: auto; */
      margin-left: 40px;
    }
    .circleRotate {
      display: none;
      
    }
  }
  @media only screen and (min-width: 1024px) {
    .hide-on-laptop73 {
      display: none; /* Hide the content on screens with a width of 1024 pixels or larger (laptops and desktops) */
    }
  }
  @media (min-width: 768px) and (max-width: 1366px)
  {
    .partners-right-pane{
     margin-left:-80px;
  }
  .partners-loading{
    margin-right:-60px;
  }
  }
  
  